const sizes = {
    tablet: '768px',
    tabletSm: '480px',
    mobile: '375px',
    desktopMd: '1300px'
}

export const device = {
    tablet: `(max-width: ${sizes.tablet})`,
    tabletSm: `(max-width: ${sizes.tabletSm})`,
    mobile: `(max-width: ${sizes.mobile})`,
    desktopMd: `(max-width): ${sizes.desktopMd}`
}