import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import * as EmailValidator from 'email-validator'

import { BASE_URL } from '../../api/API'

import { Input, Title, Button, Logo, TextLabel, Text, InputCheck } from '../../styledComponents/styledComponents'
import AlertInput from '../AlertInput'

import { setBusiness } from '../../reduxSlices/userSlice'

import { useCurrentWidth } from '../../hooks/useCurrentWidth'

import { setDisplayNameTemp, setDisplayEmailTemp } from '../../reduxSlices/userSlice'

import { firebaseAnalytics } from '../../firebase'

import useMessageHandler from '../../hooks/useMessageHandler'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure()

const CreateKioskAccount = () => {
    const dispatch = useDispatch()
    const { width } = useCurrentWidth()
    const { user, userPhoneNumber } = useSelector(state => state.user)

    const { handleCode, errorMessages } = useMessageHandler()

    const [isDisabled, setIsDisabled] = useState(false)

    let history = useHistory()


    const [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false)
    const [emailInvalidEntry, setEmailInvalidEntry] = useState(false)
    //btn create account validation
    const [statusIncompleted, setStatusIncompleted] = useState(true)

    const [kioskAccount, setKioskAccount] = useState({
        displayName: '',
        name: '',
        phoneNumber: userPhoneNumber || '',
        email: '',
        termsAndConditionsAgreed: false
    })

    //the result of inputs validation will be stored here, initially starts as undefined
    const [fieldsValidated, setFieldsValidated] = useState({
        displayName: undefined,
        name: undefined,
        email: undefined,
    })

    const handleKioskData = ({ target }) => {
        emailAlreadyInUse && setEmailAlreadyInUse(false)
        emailInvalidEntry && setEmailInvalidEntry(false)
        setKioskAccount({
            ...kioskAccount,
            [target.name]: target.type === 'checkbox' ? !kioskAccount[target.name] : target.value
        })
    }

    let inputFocusRef = useRef(null)
    useEffect(() => {
        inputFocusRef.current.focus()
        return () => {
            inputFocusRef.current = null
        }
    }, [])

    useEffect(() => {
        checkInputsCompleted()
    }, [kioskAccount, emailAlreadyInUse, emailInvalidEntry]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkInputsCompleted = () => {
        if (!Object.values(kioskAccount).includes('') && kioskAccount.termsAndConditionsAgreed === true && !emailAlreadyInUse && !emailInvalidEntry) {
            setStatusIncompleted(false)
        } else {
            setStatusIncompleted(true)
        }
    }

    const createKioskAccount = async () => {
        setIsDisabled(true)
        const { accessToken } = user._delegate
        const { displayName, phoneNumber, email, name } = kioskAccount
        let businessProfile = {
            displayName,
            phoneNumber,
            email: email.trim(),
            name
        }
        try {
            let response = await axios.post(`${BASE_URL}/business`, { ...businessProfile }, {
                headers: {
                    'Content-Type': 'application/json',
                    'device': 'onboarding',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + accessToken
                },
            })
            if (response.status >= 200 && response.status < 300) {
                firebaseAnalytics.logEvent('sign_up', {
                    method: 'phone'
                })
                firebaseAnalytics.logEvent('new_user')
                console.info(`New kiosk account created with phone number ${businessProfile.phoneNumber}, the Id assigned is ${response.data.businessId}`);
                dispatch(setDisplayNameTemp(displayName))
                dispatch(setDisplayEmailTemp(email))
                dispatch(setBusiness([{id: response.data.businessId}]))
                history.push('/onboarding')
                handleCode(605, true, 'success')
                setIsDisabled(false)
            }
        } catch (error) {
            if (error.response.status === 409) {
                //@ Email already in use
                setEmailAlreadyInUse(true)
                handleCode(607)
                setIsDisabled(false)
            } else {
                //@ The business couldn't be created, internal database-server error
                handleCode(604, true)
                // toast.error('Business could not be created, please try later.', { position: toast.POSITION.TOP_RIGHT })
                setIsDisabled(false)
            }
        }
    }

    const validateInput = ({ target }) => {
        switch (target.name) {
            case 'displayName':
                if (target.value.length > 0) {
                    setFieldsValidated({
                        ...fieldsValidated,
                        [target.name]: true
                    })
                } else {
                    setFieldsValidated({
                        ...fieldsValidated,
                        [target.name]: false
                    })
                    handleCode(602)
                }
                break;
            case 'name':
                if (target.value.length > 0) {
                    setFieldsValidated({
                        ...fieldsValidated,
                        [target.name]: true
                    })
                } else {
                    setFieldsValidated({
                        ...fieldsValidated,
                        [target.name]: false
                    })
                    handleCode(606)
                }
                break;
            case 'email':
                if (EmailValidator.validate(target.value)) {
                    setFieldsValidated({
                        ...fieldsValidated,
                        [target.name]: true
                    })
                    setEmailInvalidEntry(false)
                } else if (!target.value.length > 0) {
                    setFieldsValidated({
                        ...fieldsValidated,
                        [target.name]: 'invalid'
                    })
                    setEmailInvalidEntry(true)
                    handleCode(600)
                } else {
                    setFieldsValidated({
                        ...fieldsValidated,
                        [target.name]: 'invalid'
                    })
                    setEmailInvalidEntry(true)
                    handleCode(600)
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="createKiosk-form">
            <div className="createKiosk-innerForm">
                <div className="mb-20">
                    <Logo>KIOS</Logo>
                </div>
                <div className="pb-20">
                    <Title>Create your account</Title>
                </div>
                <label>
                    <div>
                        <TextLabel>Full name</TextLabel>
                        <TextLabel color="red"> *</TextLabel>
                    </div>
                    <Input
                        status={fieldsValidated.displayName ? 'success' : fieldsValidated.displayName === false ? 'alert' : ''}
                        type="text"
                        name='displayName'
                        placeholder="Enter here..."
                        ref={inputFocusRef}
                        onChange={(e) => handleKioskData(e)}
                        onBlur={(e) => validateInput(e)}
                    />
                    <div className="alert-container">
                        {
                            fieldsValidated.displayName === false && <AlertInput>{errorMessages[602]}</AlertInput>
                        }
                    </div>
                </label>
                <label>
                    <div>
                        <TextLabel>Business name</TextLabel>
                        <TextLabel color="red"> *</TextLabel>
                    </div>
                    <Input
                        status={fieldsValidated.name ? 'success' : fieldsValidated.name === false ? 'alert' : ''}
                        type='text'
                        name='name'
                        placeholder="Enter here..."
                        onChange={(e) => handleKioskData(e)}
                        onBlur={(e) => validateInput(e)}
                    />
                    <div className="alert-container">
                        {fieldsValidated.name === false && <AlertInput>{errorMessages[606]}</AlertInput>}
                    </div>
                </label>
                <label>
                    <div>
                        <TextLabel>Mobile number</TextLabel>
                        <TextLabel color="red"> *</TextLabel>
                    </div>
                    <Input
                        type='text'
                        name='phoneNumber'
                        value={kioskAccount.phoneNumber}
                        disabled={true}
                    />
                    <div className="alert-container" />
                </label>
                <label>
                    <div>
                        <TextLabel>Email</TextLabel>
                        <TextLabel color="red"> *</TextLabel>
                    </div>
                    <Input
                        status={
                            emailAlreadyInUse ? 'alert' :
                                fieldsValidated.email === true ? 'success' :
                                    (fieldsValidated.email === 'empty' || fieldsValidated.email === 'invalid') && 'alert'
                        }
                        type='text'
                        name='email'
                        placeholder="Enter here..."
                        onChange={(e) => handleKioskData(e)}
                        onBlur={(e) => validateInput(e)}
                    />
                    <div className="alert-container">
                        {
                            (emailAlreadyInUse || emailInvalidEntry) && <AlertInput>{emailAlreadyInUse ? errorMessages[607] : errorMessages[600]}</AlertInput>
                        }
                    </div>
                </label>
                <div className="createKiosk-checkboxContainer">
                    <InputCheck
                        type='checkbox'
                        name='termsAndConditionsAgreed'
                        checked={kioskAccount.termsAndConditionsAgreed}
                        onChange={(e) => handleKioskData(e)}
                    />
                    <Text> I agree to KIOS <Link to={{ pathname: "https://www.kios.cloud/privacy-policy" }} target="_blank">Privacy Policy</Link>, <Link to={{ pathname: "https://www.kios.cloud/terms-of-service" }} target="_blank"> Terms of Service</Link>, and <Link to={{ pathname: "https://www.kios.cloud/esign-consent" }} target="_blank">E-Sign Consent</Link> </Text>
                </div>
                <Button
                    width={width <= 768 ? '296px' : '443px'}
                    color="white"
                    disabled={statusIncompleted || isDisabled}
                    onClick={createKioskAccount}
                >Create account</Button>
            </div>
        </div>
    )
}

export default CreateKioskAccount
