import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {firebaseAnalytics, firestore} from '../../firebase'
import { useCurrentWidth } from '../../hooks/useCurrentWidth'

import { Logo, Title, Text, Button, ButtonNonFilled } from '../../styledComponents/styledComponents'

import Modal from '../Onboarding/Modal'
import {setOnboardingStatus} from "../../reduxSlices/onboardingSlice";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {BASE_URL} from "../../api/API";
import {Loader} from "../Loader";
import MuiButton from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const ContinueOnboarding = ({ businesses }) => {
    const { width } = useCurrentWidth()
    const dispatch = useDispatch()

    const { user } = useSelector(state => state.user)
    const accessToken = user?._delegate.accessToken

    const { onboardingStatus } = useSelector(state => state.onboarding)
    let onboardingCompleted = false;
    let squareIntegrated = false;

    if (onboardingStatus) {
        onboardingStatus.forEach(step => {
            if (step[0] === 'onboardingComplete') {
                onboardingCompleted = step[1] === 'complete'
            } else if (step[0] === 'squareIntegration') {
                squareIntegrated = step[1] === 'complete'
            }
        })
    }

    useEffect(() => {
        firestore.collection('businesses').doc(`${businesses[0].id}/private/onboarding`)
            .onSnapshot((doc) => {
                dispatch(setOnboardingStatus(Object.entries(doc.data().onboardingStatus)))
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    let history = useHistory()

    const initOnboarding = () => {
        history.push('/onboarding')
    }

    const buyNewKiosk = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/business/${businesses[0].id}/onboarding/step`, {
                    "kioskPurchase": "pending",
                    "shippingInfo": "pending",
                    "kioskCheckout": "pending"
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'device': 'onboarding',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer ' + accessToken
                    }
                }
            )
            // todo chequear response 200
            initOnboarding()
        } catch (error) {
            console.error('Oh no');
        }
    }

    const handleInitOrBuyNew = () => {
        onboardingCompleted ? squareIntegrated ? buyNewKiosk() : handleOpen() : initOnboarding();
    }

    useEffect(() => {
        firebaseAnalytics.logEvent('returning_user', {
            method: 'phone'
        })
        firebaseAnalytics.logEvent('login', {
            method: 'phone'
        })

        console.info(`BusinessId ${businesses[0].id} has logged in`);
        console.info(`BusinessId ${businesses[0].id} is a returning user`);
    }, [])

    //modal
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        window.addEventListener('keypress', e => {
            if(e.key === 'Enter') {
                initOnboarding()
            }
        });
    }, []);

    const [openTooltip, setOpenTooltip] = React.useState(false);

    const handleClose = () => {
        setOpenTooltip(false);
    };

    const handleOpen = () => {
        setOpenTooltip(true);
        setTimeout(handleClose, 5000);
    };

    return (
        <div className="accessCardContainer">
            <Modal
                open={open}
                onCloseModal={onCloseModal}
                unlinkAccount={onboardingCompleted}
                startOver
            />
            { !onboardingStatus.length ?
                <Loader/> :
                <div className="accessCard">
                    <div className="mb-4vh accessCard-logoGroup">
                        <Logo>KIOS</Logo>
                        <div className="accessCard-businessName">
                            <Text>{businesses[0].name}</Text>
                        </div>
                    </div>
                    <div className="accessCard-dataContainer mt-60">
                        <div className="accessCard-data">
                            <Title>Welcome back!</Title>
                            <div className="mt-8 accessCard-text">
                                <Text
                                    color="#222222">{ onboardingCompleted ? 'Would you like to order more kiosks?' : 'Want to pick up where you left off?' }</Text>
                            </div>
                            <div className="mt-60 continueOnboarding-btnsContainer">
                                <Button width={width <= 480 ? '175px' : 'auto'}
                                        color="white"
                                        style={{opacity: onboardingCompleted && !squareIntegrated ? '0.5' : '1'}}
                                        onClick={handleInitOrBuyNew}>{ onboardingCompleted ? 'Buy a Kiosk' : 'Continue progress' }
                                </Button>
                                { !onboardingCompleted ?
                                    <ButtonNonFilled width={width <= 480 ? '175px' : 'auto'} onClick={onOpenModal}>Logout</ButtonNonFilled> :
                                    squareIntegrated ?
                                        <ButtonNonFilled width={'auto'} minWidth={'120px'} height={'50px'} onClick={onOpenModal}>Unlink Square account</ButtonNonFilled> :
                                        <Tooltip open={openTooltip} onClose={handleClose} title="Link Square account to order more kiosks." placement="top" arrow>
                                            <MuiButton style={{padding: 0}}>
                                                <ButtonNonFilled width={width <= 480 ? '175px' : 'auto'} onClick={initOnboarding}>Link Square account</ButtonNonFilled>
                                            </MuiButton>
                                        </Tooltip>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ContinueOnboarding
