import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Text } from '../../../styledComponents/styledComponents'

import SummaryItem from './SummaryItem'

const Summary = ({ lineItems, trackingNumber, trackingUrl, totalAmount, totalAmountDetail }) => {

    useEffect(() => {
        lineItems = lineItems.sort((a, b) => (a.price < b.price) ? 1 : - 1)
    }, [])

    return (
        <>
            <div className="summaryTitle-container">
                <Text color="#797b7c" fontWeight='600'>SUMMARY</Text>
                <Text fontWeight="600">
                    <Link className="link" to={{ pathname: trackingUrl }} target="_blank">
                        UPS Tracking number/s:
                    </Link>
                </Text>
                <Text color="black">
                    {trackingNumber}
                </Text>
            </div>
            <div className="mt-3" />
            <div className="kioskCheckout-summaryContainer">
                {
                    lineItems.map(({ name, quantity, price }) => {
                        return <SummaryItem
                            key={name}
                            quantity={quantity}
                            name={name}
                            price={price}
                        />
                    })
                }

                <SummaryItem
                    name='Subtotal'
                    price={totalAmountDetail.subTotalAmount}
                />

                <div className="lineDivisor-subTotal" />


                <SummaryItem
                    name='Shipping cost'
                    price={totalAmountDetail.shippingAmount}
                />

                <SummaryItem
                    name='Taxes'
                    price={totalAmountDetail.taxesAmount}
                />

                <div className="lineDivisor-totalAmount" />
                <div className="kioskCheckout-summaryItem totalAmount">
                    <div>
                        <Text>Total amount</Text>
                    </div>
                    <Text fontWeight='600'>${(totalAmount).toFixed(2)}</Text>
                </div>

            </div>
        </>
    )
}

export default Summary
