import React, { useEffect } from 'react'
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { setBusiness, setLoading } from '../reduxSlices/userSlice';

import { BASE_URL } from '../api/API';

import CreateKioskAccount from '../components/Business/CreateKioskAccount';
import ContinueOnboarding from '../components/Business/ContinueOnboarding';

import { Loader } from '../components/Loader';

import useMessageHandler from '../hooks/useMessageHandler';
import SelectBusiness from "../components/Business/SelectBusiness";

const Business = (props) => {
    const dispatch = useDispatch()
    const { businesses, loading, user } = useSelector(state => state.user)
    const { handleCode } = useMessageHandler()

    useEffect(() => {
        user && (businesses?.length !== 1) && getUserBusiness()
        redirect()
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    const redirect = () => {
        //@ no user logged
        if (!user) {
            props.history.push('/')
        }
        return null
    }

    const getUserBusiness = async () => {
        const { accessToken } = user._delegate
        try {
            let response = await axios.get(`${BASE_URL}/user/businessData`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            dispatch(setBusiness(response.data.businesses))
            dispatch(setLoading(false))
        } catch (error) {
            //@ Error happened when trying to get the user businesses
            handleCode(604, true)
        }
    }

    return (
        <div className="mainBgContainer">
            {
                loading || !businesses
                    ? <Loader />
                    : businesses.length > 0
                        ? businesses.length === 1 ?
                            <ContinueOnboarding businesses={businesses}/>
                            : <SelectBusiness businesses={businesses} userId={user.uid}/>
                        : <CreateKioskAccount />
            }
        </div>
    )
}

export default Business
