import React, { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { setProducts } from '../../../reduxSlices/onboardingSlice'

import { Text, PurchaseButtonWrapper, PurchaseButton } from '../../../styledComponents/styledComponents'

const ItemKiosk = ({ product, setItemsInCart, itemsInCart, products }) => {
    const dispatch = useDispatch()
    const [isSelected, setIsSelected] = useState(false)

    const handleProducts = (action) => {
        let productsModified = products.map(item => {
            if (item.id === product.id) {
                let itemModified;
                if (action === 'add') {
                    itemModified = { ...item, quantity: item.quantity + 1 }
                    setItemsInCart(itemsInCart + 1)
                    return {
                        ...itemModified
                    }
                } else if (action === 'remove' && item.quantity >= 1) {
                    setItemsInCart(itemsInCart - 1)
                    itemModified = { ...item, quantity: item.quantity - 1 }
                    return {
                        ...itemModified
                    }
                } else {
                    return item
                }
            }
            return item
        })
        dispatch(setProducts(productsModified))
    }

    useEffect(() => {
        if (product.quantity > 0) {
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
    }, [product.quantity])

    return (
        <div className="itemKiosk-card">
            <div>
                <div className="itemKiosk-image" style={{
                    backgroundImage: `url('${product.imgUrl[0]}')`
                }} />
            </div>
            <div className="itemKiosk-middleContainer">
                <Text fontWeight="600">${product.price}</Text>
                <Text>before taxes</Text>
            </div>
            <div className="itemKiosk-nameDescriptionContainer">
                <Text fontWeight="600" fontSize="18px">{product.name}</Text>
                <div>
                    <Text>{product.description}</Text>
                </div>
            </div>

            <PurchaseButtonWrapper isSelected={product.quantity > 0} onClick={() => !isSelected && handleProducts('add')}>
                {isSelected && <PurchaseButton left onClick={() => handleProducts('remove')}>-</PurchaseButton>}
                {isSelected ? product.quantity : 'Add to cart'}
                {isSelected && <PurchaseButton right onClick={() => handleProducts('add')}>+</PurchaseButton>}
            </PurchaseButtonWrapper>
        </div>
    )
}

export default ItemKiosk
