import React, {useEffect, useRef, useState} from 'react'
import { PaginationNumber, PaginationWrapper } from '../../../../styledComponents/styledComponents';

const Pagination = ({ paginate, currentPage, pageNumbers }) => {
    const [overflowActive, setOverflowActive] = useState(false);
    const wrapperRef = useRef();

    const isOverflowActive = (event) => {
        return event.offsetWidth < event.scrollWidth;
    }

    useEffect(() => {
        setOverflowActive(isOverflowActive(wrapperRef.current));
    }, [isOverflowActive]);

    let width = window.innerWidth

    return (
        <div className="mt-pagination">
            <PaginationWrapper ref={wrapperRef} center={!overflowActive}>
                {
                    pageNumbers.map(number => {
                        return <PaginationNumber onClick={() => paginate(number)} key={number} currentPage={currentPage === number}>
                            {number}
                        </PaginationNumber>
                    })
                }
                {
                    width > 480 && <div className={currentPage === pageNumbers.length ? "nextPage-btnContainer nextPage-disabled" : "nextPage-btnContainer"}>
                        <ion-icon name='play' onClick={() => {
                            if (currentPage < pageNumbers.length) {
                                paginate(currentPage + 1)
                            }
                        }} />
                    </div>
                }
            </PaginationWrapper>
        </div>
    )
}

export default Pagination
