import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: undefined,
    displayNameTemp: undefined,
    displayEmailTemp: undefined,
    businesses: undefined,
    loading: false,
    userPhoneNumber: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        logOut: (state, action) => {
            state.user = undefined
        },
        setBusiness: (state, action) => {
            state.businesses = action.payload
        },
        setDisplayNameTemp: (state, action) => {
            state.displayNameTemp = action.payload
        },
        setDisplayEmailTemp: (state, action) => {
            state.displayEmailTemp = action.payload
        },
        setUserPhoneNumber: (state, action) => {
            state.userPhoneNumber = action.payload
        }
    }
})

export const { setBusiness, setUser, setLoading, logOut, setDisplayNameTemp, setDisplayEmailTemp, setUserPhoneNumber } = userSlice.actions;
export default userSlice.reducer;