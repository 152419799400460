import React, { useState, useEffect } from 'react'
import { CategoryButtonWrapper, CategoryCircleButton, CategoryName } from '../../../../styledComponents/styledComponents'

const CategoryItem = ({ category, setCategoriesSelected, categoriesSelected }) => {
    const [isSelected, setIsSelected] = useState(false)

    const handleCategory = () => {
        if (isSelected) {
            let categoriesFiltered = categoriesSelected.filter(cat => cat.id !== category.id)
            setCategoriesSelected(categoriesFiltered)
        } else {
            setCategoriesSelected([...categoriesSelected, { id: category.id }])
        }
    }

    const checkCategoryStatus = () => {
        let categorySelected = categoriesSelected.filter(cat => cat.id === category.id)
        if (categorySelected.length > 0) {
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
    }

    useEffect(() => {
        checkCategoryStatus()
    }, [categoriesSelected]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CategoryButtonWrapper onClick={handleCategory} isSelected={isSelected}>
            <CategoryName>{category.name}</CategoryName>
            <CategoryCircleButton isSelected={isSelected} />
        </CategoryButtonWrapper>
    )
}

export default CategoryItem
