import React from 'react'
import { Text } from '../../../styledComponents/styledComponents'

const OrderInfoBar = ({ order, orderDate }) => {
    return (
        <>
            <div className="amountPaid">
                <Text color="#797b7c" fontWeight='600'>AMOUNT PAID</Text>
                <Text>${order.totalAmount}</Text>
            </div>
            <div className="margin-both-receiptBarItem">
                <Text color="#797b7c" fontWeight='600'>DATE PAID</Text>
                <Text>{orderDate}</Text>
            </div>
            <div>
                <Text color="#797b7c" fontWeight='600'>PAYMENT METHOD</Text>
                <Text>{order.paymentMethod}</Text>
            </div>
        </>
    )
}

export default OrderInfoBar
