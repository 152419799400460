import React from 'react'

const AlertInput = ({ children }) => {
    return (
        <>
            {
                children?.length > 0 && <div className="alert">
                    <ion-icon size="small" name='warning-outline'></ion-icon>
                    <span>{children}</span>
                </div>
            }
        </>
    )
}

export default AlertInput
