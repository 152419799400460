import React from 'react'
import { Text } from '../styledComponents/styledComponents'

export const Loader = ({ message = undefined }) => {
    return (
        <>
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
            {message && <Text mt='18px'>{message}</Text>}
        </>
    )
}
