import React from 'react'

import { Text } from '../../../styledComponents/styledComponents'

const SummaryItem = ({ quantity, name, price }) => {
    return (
        <div className="kioskCheckout-summaryItem">
            <div>
                {quantity && <Text>{quantity} x</Text>}
                <Text> {name}</Text>
            </div>
            <div className="priceSummaryItem">
                <Text fontWeight='500'>${(price).toFixed(2)}</Text>
            </div>
        </div>
    )
}

export default SummaryItem
