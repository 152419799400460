import styled, { css } from 'styled-components'

//assets
import logo from '../assets/logo.png'
import categorySelected from '../assets/categorySelected.png'
import receipt from '../assets/receipt.png'
import transactionFailed from '../assets/transactionFailed.png'

//sizes
import { device } from './devices'

//buttons
export const Button = styled.button`
    width: ${props => props.width ? props.width : '97px'};
    height: ${props => props.height ? props.height : '42px'};
    color: ${props => props.color};
    padding: 11px 18px;
    background: ${props => props.bg ? props.bg : '#52A66F'};
    border-radius: ${props => props.borderRadious ? props.borderRadious : '6px'};
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.fontSize ? props.fontSize : '15px'};
    cursor: pointer;
    letter-spacing: 0.4px;

    :disabled {
        opacity: 0.5;
        cursor: initial;
    }

    :hover:enabled {
        background-color: ${props => props.hoverBg ? props.hoverBg : '#38744D'};
        transition: all 300ms;
    }

    //in case of outline button, props needed: outline(bool), borderColor(str)
    ${props => props.outline && css`
        background-color: transparent;
        border: solid 1px ${props.borderColor};
        width: 130px;
    `}

    ${props => props.bold && css`
        font-weight: bold;
    `}

    ${props => props.mobile && css`
        min-width: 153px;
    `}

`

export const ButtonNonFilled = styled.button`
    width: ${props => props.width ? props.width : '120px'};
    height: ${props => props.height ? props.height : '42px'};
    color: ${props => props.color ? props.color : '#52A66F'};
    padding: 11px 18px;
    background: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
    border: none;
    font-size: ${props => props.fontSize ? props.fontSize : '14px'};
    cursor: pointer;
    border-radius: 6px;
    min-width: ${props => props.minWidth ? props.minWidth : 'auto'};
    
    :focus {
        outline: none;
    }

    :disabled {
        opacity: 0.5;
        cursor: initial;
    }

    :hover:enabled {
        color: ${props => props.hoverColor ? props.hoverColor : '#38744D'};
        transition: all 300ms;
    }

    ${props => props.bold && css`
        font-weight: bold;
    `}

    ${props => props.mobile && css`
        width: 153px;
    `}

    @media ${device.mobile} {
        padding: 0px;
    }
`

export const CategoryButtonWrapper = styled.div`
    width: 344px;
    height: 52px;
    border-radius: 89px;
    border: 1px solid #C4C4C4;
    position: relative;
    transition: all 1s;
    margin: 0 auto;
    margin-bottom: 20px;
    cursor: pointer;

    ${props => props.isSelected && css`
        background: rgb(173,235,192);
        background: linear-gradient(90deg, rgba(173,235,192,1) 0%, rgba(191,212,204,1) 85%);
        border: 1px solid transparent;
    `}

    @media ${device.tablet} {
        width: 90%;
    }
`

export const CategoryCircleButton = styled.button`
    width: 28px;
    height: 28px;
    border-radius: 100px;
    border: 1px solid #C4C4C4;
    position: absolute;
    top: 12px;
    right: 19px;
    background: transparent;
    cursor: pointer;

    ${props => props.isSelected && css`
        background-image: url(${categorySelected});
        background-position: center;
        background-size: cover;
        border: 1px solid white;
    `}
`

export const CategoryName = styled.div`
    height: 28px;
    font-size: 17px;
    font-weight: 600;
    color: #000;
    position: absolute;
    top: 14px;
    left: 19px;
    letter-spacing: 0.2px;
`


export const PurchaseButtonWrapper = styled.div`
    width: 90%;
    height: 34px;
    background-color: #52A66F;
    border-radius: 89px;
    position: relative;
    transition: all 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: white;

    :hover:enabled {
        color: ${props => props.hoverColor ? props.hoverColor : '#38744D'};
        transition: all 300ms;
    }

    ${props => props.isSelected && css`
        background-color: #38744D;
    `}
`

export const PurchaseButton = styled.button`
    position: absolute;
    color: white;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 24px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 6px;

    ${props => props.left && css`
        left: 17px;
        
    `}

    ${props => props.right && css`
        right: 15px;
    `}
`

//logo
export const Logo = styled.div`
    color: #52A66F;
    font-size: 3.6rem; 

    @media ${device.tablet} {
        font-size: 3.2rem;
    }

    @media ${device.mobile} {
        font-size: 3rem;
    }
`

//text
export const Title = styled.span`
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    color: #000;

    @media ${device.tablet} {
        font-size: 24px;
        line-height: 30px;
    }

    @media ${device.mobile} {
        font-size: 18px;
        line-height: 26px;
    }
`

//text
export const HeavyTitle = styled.span`
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: white;
    margin-top: 6%;
    padding-block: 25px;

    @media ${device.tablet} {
        font-size: 32px;
        line-height: 30px;
    }

    @media ${device.mobile} {
        font-size: 26px;
        line-height: 26px;
    }
`
//div
export const HeavyTitleWrapper = styled.div`
    width: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
`

export const DocumentsInfoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
`

export const DocumentsInfoWrapper = styled.div`
    width: 50%;
`

//text
export const SubTitle = styled.span`
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #000;
`

//input
export const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #E0E2E7;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    width: ${props => props.width ? props.width : '443px'};
    height: 54px !important;
    flex-direction: column;
    padding: 12px 16px !important;
    font-size: 14px;

    &::placeholder {
        color: #6F727A;
        font-size: 13.9px;
        letter-spacing: 0.3px;
        opacity: 0.8;
    }

    ${props => props.status === 'success'
        ? css`
            border: 1px solid #52A66F;
        `
        : props.status === 'alert'
        && css`
            background: #FFF5F5;
            border: 1px solid #F32D2D;
        `
    }

     //mediaqueries
     @media ${device.tablet} {
        width: 296px;
        height: 54px;

        &::placeholder {
            font-size: 12px;
            line-height: 24px;
        }
    }
`

// input label
export const TextLabel = styled.span`
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.1px;
    color: ${props => props.color ? props.color : '#000'};
`

// Text
export const Text = styled.span`
    font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
    font-size: ${props => props.fontSize ? props.fontSize : '15px'};
    letter-spacing: 0.2px;
    line-height: 24px;
    color: ${props => props.color ? props.color : '#000'};
    margin-top: ${props => props.mt ? props.mt : 0};
    
    ${props => props.textCenter && css`
        text-align: center;
    `}

    ${props => props.sm && css`
        font-size: 13px;
        line-height: 21px;
    `}

    @media ${device.tablet} {
        font-size: ${props => props.fontSize ? props.fontSize : '14px'};
        line-height: 20px;
    }

    @media ${device.mobile} {
        font-size: ${props => props.fontSize ? props.fontSize : '12px'};
        line-height: 18px;
    }
`

export const InputCheck = styled.input`
    width: 20px;
    height: 20px;
    border: 1px solid #E0E2E6;
    box-sizing: border-box;
    border-radius: 4px;

`

// pagination
export const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    overflow: auto;
    justify-content: flex-start;
    padding-bottom: 10px;

    ${props => props.center && css`
        justify-content: center;
    `}
`

export const PaginationNumber = styled.span`
    font-size: 18px;
    color: grey;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    margin-inline: 10px;

    :hover { 
        color: black;
        opacity: 0.7;
    }

    ${props => props.currentPage && css`
        color: black;
    `}
`

//stripe
export const StripeReceiptWrapper = styled.div`
    width: 470px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    border-color: white;
    background-color: #f9f9f9;
    align-items: center;
    box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 24px;

    @media ${device.mobile} {
        width: 350px;
        min-height: 225px;
    }
`

export const StripeReceiptImage = styled.div`
    /* margin-top: 50px; */
    background-position: center;
    background-size: contain;
    width: 135px;
    height: 120px;
    margin-bottom: 8px;

    ${props => props.success ? css`
        background-image: url(${receipt});
        box-shadow: inset 10px 10px 10px 10px #f9f9f9;
        ` : css`
        background-image: url(${transactionFailed});
        width: 65px;
        height: 65px;
        `
    }

    ${props => props.successWhite && css`
        box-shadow: inset 10px 10px 10px 15px #FFF;
    `
    }
`

export const StripeTransaccionTitle = styled.span`
    font-size: 19px;
    font-weight: 600;
    text-align: center;

    @media ${device.tablet} {
        font-size: 18px;
    }

    @media ${device.mobile} {
        font-size: 16px;
    }
`

export const StripePrice = styled.span`
    font-size: 32px;
    font-weight: 600;
`

export const StripeText = styled.span`
    font-size: 14px;
    color: ${props => props.color ? props.color : 'black'};
`

export const InfoReceiptWrapper = styled.div`
    width: 90%;
`

export const InfoReceiptItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-block: 8px;
`

export const DownloadReceiptButton = styled.button`
    width: 165px;
    height: 32px;
    border-radius: 5px;
    font-weight: 600;
    background-color: #4968ff;
    color: white;
    border: none;
    margin-top: 10px;
    cursor: pointer;

    :hover {
        opacity: 0.8;
        transition: all 300ms;
    }
`
