import React from 'react'
import KioskCheckout from '../components/Onboarding/kioskCheckout/KioskCheckout';
import axios from 'axios'
import { BASE_URL } from '../api/API'
import {useSelector} from "react-redux";

const CheckoutCompleted = () => {
    const { user, businesses } = useSelector(state => state.user)

    let businessId = businesses[0].id
    const { accessToken } = user._delegate
    const completeCheckout = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/business/${businessId}/onboarding/step`, {
                    "kioskCheckout": "complete"
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'device': 'onboarding',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer ' + accessToken
                    }
                }
            )
        } catch (error) {
            console.error('Oh no');
        }
    }

    completeCheckout();

    return (
        <>
            <div className="onboardingFinished-container">
                <KioskCheckout />
            </div>
        </>
    )
}

export default CheckoutCompleted
