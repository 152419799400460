import React from 'react'
import { StripeReceiptWrapper, StripeReceiptImage, StripeText, StripeTransaccionTitle, InfoReceiptWrapper, InfoReceiptItemWrapper, StripePrice } from '../../../../styledComponents/styledComponents'

const PaymentFailed = () => {
    return (
        <div className="stripeReceipt-container">
            <StripeReceiptWrapper>
                <div className="stripeReceipt-innerContainer">
                    <StripeReceiptImage />
                    <StripeText color="#767676">Transaction failed</StripeText>
                    <div className="paymentFailed-mt" />
                    <StripePrice>Oh no!,</StripePrice>
                    <StripeTransaccionTitle>Please try again or use a different payment card.</StripeTransaccionTitle>
                </div>
                <div className="stripeReceipt-innerContainer">
                    <InfoReceiptWrapper>
                        <InfoReceiptItemWrapper>
                        </InfoReceiptItemWrapper>
                        <InfoReceiptItemWrapper>
                        </InfoReceiptItemWrapper>
                        <InfoReceiptItemWrapper>
                        </InfoReceiptItemWrapper>
                    </InfoReceiptWrapper>
                </div>
            </StripeReceiptWrapper>
        </div>

    )
}

export default PaymentFailed
