import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore'

const firebaseConfig = require('./config/firebaseConfig.json')[process.env.REACT_APP_NODE_ENV]

firebase.initializeApp(firebaseConfig)
export const firebaseAnalytics = firebase.analytics();
export const firestore = firebase.firestore()


export default firebase
