import React, { useEffect, useState } from 'react'
import { firebaseAnalytics } from '../../../../firebase'

import axios from 'axios'
import { BASE_URL } from '../../../../api/API'

import SectionTitle from '../../SectionTitle'
import { Button, Text } from '../../../../styledComponents/styledComponents'

import { LoaderBtn } from '../../../LoaderBtn'

import { useSelector } from 'react-redux'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useHistory } from "react-router-dom";
toast.configure()

const Square = ({ getNextStep, onboardingStatus, handleCode }) => {
    const [linkAlreadyUsed, setLinkAlreadyUsed] = useState(false)

    const [processingEvent, setProcessingEvent] = useState(false)
    const [squareCompleted, setSquareCompleted] = useState(false)
    const [squareBtnDisabled, setSquareBtnDisabled] = useState(true)
    const [squareUrl, setSquareUrl] = useState('')
    let history = useHistory()

    const { user, businesses } = useSelector(state => state.user)
    const userId = user.uid;

    const checkSquareStatus = () => {
        onboardingStatus.map(step => {
            if (step[0] === 'squareIntegration' && step[1] === 'complete') {
                if (!processingEvent) {
                    firebaseAnalytics.logEvent('login_square', {
                        business_id: businesses[0].id
                    })
                    console.info(`BusinessId ${businesses[0].id} completed square login`)
                }
                setSquareCompleted(true)
                setProcessingEvent(true)
            }
            return step
        })
    }

    const redirectOnSquareCompleted = () => {
        console.info(0)
        setTimeout(() => {
            const onboardingCompleted = onboardingStatus.find(step => step[0] === 'onboardingComplete')[1] === 'complete'
            if (!onboardingCompleted) {
               getNextStep()
            } else {
                history.push(`/business/${userId}`)
            }

            setProcessingEvent(false)
        }, 7000);
    }

    useEffect(() => {
        squareCompleted && redirectOnSquareCompleted()
    }, [squareCompleted]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        checkSquareStatus()
    }, [onboardingStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    const getUrl = async () => {
        setSquareBtnDisabled(true)
        const { accessToken } = user._delegate
        try {
            let response = await axios.get(`${BASE_URL}/business/${businesses[0].id}/integration/squareUrl`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            setSquareUrl(response.data.url)
            setSquareBtnDisabled(false)
        } catch (error) {
            handleCode(604, true)
        }
    }

    useEffect(() => {
        getUrl()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleSquareLinkStatus()
    }, [squareUrl]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSquareLinkStatus = () => {
        if (squareUrl) {
            setSquareBtnDisabled(false)
        } else {
            setSquareBtnDisabled(true)
        }
    }

    const openWindowCentered = ({ url, w, h }) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, "_blank",
            `
          name=linkingSquare,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
        )

        if (window.focus) newWindow.focus();
    }

    return (
        <>
            <SectionTitle title="Link Square account" text="Let’s get you set up! First things first, link your Square account to help us cook up your kiosk menu." />
            <div className="onboarding-mainSectionContainer">
                <div className="squareDefault-card">
                    <div className="squareDefault-buttonContainer">
                        <Button width="210px" color="white" disabled={squareCompleted ? true : squareBtnDisabled} onClick={
                            () => {
                                if (linkAlreadyUsed) {
                                    getUrl()
                                    openWindowCentered({ url: squareUrl, title: 'xtf', w: 800, h: 650 })
                                } else {
                                    openWindowCentered({ url: squareUrl, title: 'xtf', w: 800, h: 650 })
                                    setLinkAlreadyUsed(true)
                                    getUrl()
                                }
                            }
                        }>{squareBtnDisabled
                            ? <LoaderBtn />
                            : squareCompleted ? 'Square account linked' : 'Link Square account'}</Button>
                    </div>
                    {squareCompleted && <div className="squareLinked-text"><Text>Your account is already linked, you will be redirected to the next step in a few seconds.</Text></div>}
                </div>

            </div>
            <div />
        </>
    )
}

export default Square
