import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import WizardItem from './WizardItem'

const WizardMobile = () => {
    return (
        <div className="wizard">
            <div>
                <WizardItem title="Link Square account" asset="squareIntegration" />
                <WizardItem title="Kiosk preview" asset="previewKiosk" line={false} />
            </div>
            <div>
                <WizardItem title="Book virtual meeting" asset="scheduleMeeting" secondCondition="configMenu" />
                <WizardItem title="Buy a kiosk" asset="kioskPurchase" line={false} />
            </div>
            <div className="justify-center">
                <WizardItem title="Complete" asset="complete" line={false} />
            </div>
        </div>
    )
}

const WizardMobileCurrent = () => {
    const { currentStep } = useSelector(state => state.onboarding)
    return (
        <div className="wizard">
            {
                currentStep === 'squareIntegration' && <div>
                    <WizardItem title="Link Square account" asset="squareIntegration" />
                    <WizardItem title="Kiosk preview" asset="previewKiosk" line={false} />
                </div>
            }
            {
                currentStep === 'previewKiosk' && <div className="ml-5">
                    <WizardItem title="Kiosk preview" asset="previewKiosk" />
                    <WizardItem title="Book virtual meeting" asset="scheduleMeeting" secondCondition="configMenu" line={false} />
                </div>
            }
            {
                (currentStep === 'scheduleMeeting' || currentStep === 'configMenu') && <div>
                    <WizardItem title="Book virtual meeting" asset="scheduleMeeting" secondCondition="configMenu" />
                    <WizardItem title="Buy a kiosk" asset="kioskPurchase" line={false} />
                </div>
            }
            {
                (currentStep === 'kioskPurchase' || currentStep === 'complete') && <div>
                    <WizardItem title="Buy a kiosk" asset="kioskPurchase" />
                    <WizardItem title="Complete" asset="complete" line={false} />
                </div>
            }
        </div>
    )
}

const WizardTablet = () => {
    return (
        <div className="wizard">
            <div>
                <WizardItem title="Link Square account" asset="squareIntegration" />
                <WizardItem title="Kiosk preview" asset="previewKiosk" />
                <WizardItem title="Book virtual meeting" asset="scheduleMeeting" secondCondition="configMenu" line={false} />
            </div>
            <div className="justify-center">
                <WizardItem title="Buy a kiosk" asset="kioskPurchase" />
                <WizardItem title="Complete" asset="complete" />
            </div>
        </div>

    )
}

const WizardTabletCurrent = () => {
    const { currentStep } = useSelector(state => state.onboarding)
    return (
        <div className="wizard">
            {
                (currentStep === 'squareIntegration' || currentStep === 'previewKiosk' || currentStep === 'scheduleMeeting' || currentStep === 'configMenu')
                && <div>
                    <WizardItem title="Link Square account" asset="squareIntegration" />
                    <WizardItem title="Kiosk preview" asset="previewKiosk" />
                    <WizardItem title="Book virtual meeting" asset="scheduleMeeting" secondCondition="configMenu" line={false} />
                </div>
            }
            {
                (currentStep === 'kioskPurchase' || currentStep === 'complete')
                && <div className="justify-center">
                    <WizardItem title="Buy a kiosk" asset="kioskPurchase" />
                    <WizardItem title="Complete" asset="complete" />
                </div>
            }
        </div>
    )
}


const Wizard = () => {
    const [isWizardOpen, setIsWizardOpen] = useState(false)
    let windowWidth = window.innerWidth
    return (
        <div className={isWizardOpen ? "wizardContainerOpen" : "wizardContainer"}>
            {
                windowWidth <= 375 && isWizardOpen
                    ? <WizardMobile />
                    : windowWidth <= 375 && !isWizardOpen
                        ? <WizardMobileCurrent />
                        : windowWidth <= 480 && isWizardOpen
                            ? <WizardTablet />
                            : windowWidth <= 480 && !isWizardOpen
                                ? <WizardTabletCurrent />
                                : <div className="wizard">
                                    <WizardItem title="Link Square account" asset="squareIntegration" />
                                    <WizardItem title="Kiosk preview" asset="previewKiosk" />
                                    <WizardItem title="Book virtual meeting" asset="scheduleMeeting" secondCondition="configMenu" />
                                    <WizardItem title="Buy a kiosk" asset="kioskPurchase" />
                                    <WizardItem title="Complete" asset="complete" />
                                </div>
            }

            {
                windowWidth <= 480 && <div className="chevron-icon-container" onClick={() => setIsWizardOpen(!isWizardOpen)}>
                    <ion-icon name={isWizardOpen ? 'chevron-up-outline' : 'chevron-down-outline'} />
                </div>
            }
        </div>
    )
}

export default Wizard
