import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { BASE_URL } from '../../../api/API'

import { useSelector } from 'react-redux'

import { Logo, Text, Button } from '../../../styledComponents/styledComponents'

import { Loader } from '../../Loader'

import Summary from './Summary'
import OrderInfoBar from './OrderInfoBar'

import useMessageHandler from '../../../hooks/useMessageHandler'

const KioskCheckout = () => {
    const { handleCode } = useMessageHandler()
    let history = useHistory()

    const [loading, setLoading] = useState(true)

    const { user, businesses } = useSelector(state => state.user)
    const userId = user.uid;

    let businessId = businesses[0].id

    const [order, setOrder] = useState({
        orderNumber: '',
        paymentDate: '',
        paymentMethod: '',
        receiptUrl: '',
        totalAmount: '',
        trackingNumber: '',
        trackingUrl: '',
        lineItems: [],
        totalAmountDetail: []
    })

    console.log('trackingNumber', order.trackingNumber);

    let orderDate;
    if (order.paymentDate.length > 0) {
        orderDate = order.paymentDate.split(',')[1] + ', ' + order.paymentDate.split(',')[2]
    }

    useEffect(() => {
        businessId && getOrderReceipt()
    }, [businessId]) // eslint-disable-line react-hooks/exhaustive-deps

    const getOrderReceipt = async () => {
        try {
            let response = await axios.get(`${BASE_URL}/receipt/details?businessId=${businessId}`)
            setOrder(response.data)
            setLoading(false)
        } catch (error) {
            handleCode(604, true)
        }
    }

    return (
        <div className="accessCardContainer">
            <div className="p-20">
                {
                    loading
                        ? <div className="loaderContainerWithMessage">
                            <Loader message="Please wait, generating receipt" />
                        </div>
                        : <>
                            <div className="accessCard-dataContainer">
                                <div className="stripeReceipt-innerContainer">
                                    <Logo>KIOS</Logo>
                                    <div className="mt-8" />
                                    <Text fontWeight="bold" fontSize="24px">Thank you for your order!</Text>
                                    <div className="mt-18" />
                                    <Text color="#575a63" fontSize="16px">Order: #{order.orderNumber}</Text>
                                </div>
                                <div className="kioskCheckout-barReceiptContainer kioskCheckout-sectionContainer">
                                    <OrderInfoBar
                                        order={order}
                                        orderDate={orderDate}
                                    />
                                </div>

                                <div className="kioskCheckout-sectionContainer">
                                    <Summary
                                        lineItems={order.lineItems}
                                        shipping={order.totalAmountDetail.shippingAmount}
                                        trackingNumber={order.trackingNumber}
                                        trackingUrl={order.trackingUrl}
                                        totalAmount={order.totalAmount}
                                        totalAmountDetail={order.totalAmountDetail}
                                    />
                                </div>
                                <div className="kioskCheckout-sectionContainer text-center">
                                    <Text>We sent you an email with your order info.</Text>
                                </div>
                                <div className="kioskCheckout-lineDivisor" />
                                <Button
                                    className="btn-home-checkout"
                                    width="130px"
                                    color="white"
                                    onClick={() => history.push(`/business/${userId}`)}>
                                    Done
                                </Button>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default KioskCheckout
