import React, { useState, useEffect } from 'react'

import firebase from '../firebase'

import { useDispatch } from 'react-redux'

import { setUser, setLoading, setUserPhoneNumber } from '../reduxSlices/userSlice'

import { usePhoneFormatter } from '../hooks/usePhoneFormatter'
import MobileNumber from '../components/Access/MobileNumber'

import libphonenumber from 'google-libphonenumber';

import useMessageHandler from '../hooks/useMessageHandler'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure()

const Access = (props) => {
    let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const dispatch = useDispatch();
    const { handleCode, errorMessages } = useMessageHandler();

    const [isVerifyingCode, setIsVerifyingCode] = useState(false)
    const [codeEnteredWrong, setCodeEnteredWrong] = useState(false)

    const { setNumberFormatted, numberFormatted, numberValid } = usePhoneFormatter()

    const [codeVerification, setCodeVerification] = useState('')

    const handleSetCodeVerification = (value) => {
        setCodeVerification(value)
        codeEnteredWrong && setCodeEnteredWrong(false)
    }

    const captchaConfiguration = () => {
        if (window.recaptchaVerifier && !isVerifyingCode) return;
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                onSignInSubmit();
            }
        });
    }


    const onSignInSubmit = async () => {
        dispatch(setLoading(true))

        let phoneNumber = numberFormatted;
        if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(phoneNumber, 'US'), 'US')) {
            if(window.recaptchaVerifier) return;

            //generate captcha
            captchaConfiguration()
            //in window.recaptchaVerifier is storaged the captcha data
            let appVerifier = window.recaptchaVerifier;
            phoneNumber = phoneUtil.formatOutOfCountryCallingNumber(phoneUtil.parse(phoneNumber, 'US'))

            // once the capcha is generated, we can signIn
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    dispatch(setUserPhoneNumber(numberFormatted))
                    dispatch(setLoading(false))
                    setIsVerifyingCode(true)
                }).catch((error) => {
                    console.log(error);
                    handleCode(604, true)
                    dispatch(setLoading(false))
                    // Error; SMS not sent
                });
        } else {
            handleCode(601, true)
            dispatch(setLoading(false))
        }
    }

    const handleVerify = () => {
        dispatch(setLoading(true))
        const code = codeVerification;
        window.confirmationResult.confirm(code).then((result) => {
            // User signed in successfully.
            dispatch(setUser(result.user))
            handleRedirectToBusiness(result.user.uid)
            dispatch(setLoading(false))
        }).catch((error) => {
            handleCode(603)
            dispatch(setLoading(false))
            setCodeEnteredWrong(true)
            // User couldn't sign in (bad verification code?)
            // alert('Wrong code')
        });
    }

    useEffect(() => {
        if (codeVerification.length === 6) {
            handleVerify()
        }
    }, [codeVerification]) // eslint-disable-line react-hooks/exhaustive-deps

    //@redirect no logged user
    const handleRedirectToBusiness = (userId) => {
        if (userId) {
            props.history.push('/business/' + userId)
        } else {
            props.history.push('/')
        }
    }

    return (
        <div className="mainBgContainer">
            {
                <MobileNumber
                    setNumberFormatted={setNumberFormatted}
                    onSignInSubmit={onSignInSubmit}
                    handleSetCodeVerification={handleSetCodeVerification}
                    handleVerify={handleVerify}
                    numberFormatted={numberFormatted}
                    numberValid={numberValid}
                    isVerifyingCode={isVerifyingCode}
                    codeVerification={codeVerification}
                    codeEnteredWrong={codeEnteredWrong}
                    errorMessages={errorMessages}
                />
            }
        </div>
    )
}

export default Access
