import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../../api/API'

import { Button, ButtonNonFilled } from '../../../styledComponents/styledComponents'
import { LoaderBtn } from '../../LoaderBtn'

import SectionTitle from '../SectionTitle'
import ItemKiosk from './ItemKiosk'
import ShippingInfo from './ShippingInfo'

import { useCurrentWidth } from '../../../hooks/useCurrentWidth'

import { firebaseAnalytics } from '../../../firebase'


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure()

const KioskPurchase = ({
    headers,
    products,
    businesses,
    currentStep,
    onOpenModal,
    handleCode
}) => {
    const { user, displayNameTemp } = useSelector(state => state.user)
    const { displayName } = user
    let name = displayNameTemp ? displayNameTemp : displayName

    const { width } = useCurrentWidth()
    const [isDisabled, setIsDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    //purchase checkout btn
    const [itemsInCart, setItemsInCart] = useState(0)
    const [showCheckout, setShowCheckout] = useState(false)

    //shippingInfo
    const [shippingCompleted, setShippingCompleted] = useState(false)
    const [addressValidated, setAddressValidated] = useState(false)

    //url to stripe
    const [stripeUrl, setStripeUrl] = useState('')

    const [shippingData, setShippingData] = useState({
        name: name || '',
        address: '',
        state: '',
        zip: '',
        city: ''
    })

    const sendShippingInformation = async () => {
        setIsLoading(true)
        setIsDisabled(true)
        let address = {
            addressLine1: shippingData.address,
            state: shippingData.state,
            zip: shippingData.zip,
            city: shippingData.city,
            country: 'US'
        }
        try {
            let response = await axios.post(`${BASE_URL}/business/${businesses[0].id}/address/shippingAddress`, { address }, { ...headers })
            if (response.status > 300) {
                handleCode(608, true)
                setAddressValidated(false)
                setShippingCompleted(false)
                setIsDisabled(false)
                setIsLoading(false)
            } else if (response.status >= 200 && response.status <= 300) {
                handleCode(609, true, 'success')
                setAddressValidated(true)
            }
        } catch (error) {
            if (error.response.status === 400) {
                handleCode(608, true)
                setAddressValidated(false)
                setShippingCompleted(false)
                setIsDisabled(false)
                setIsLoading(false)
            } else {
                handleCode(604, true)
            }
        }
    }

    const handleStripeIntegration = async () => {
        setIsLoading(true)
        try {
            let response = await axios.get(`${BASE_URL}/business/${businesses[0].id}/integration/paymentUrl`, { ...headers })
            setStripeUrl(response.data.url)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            handleCode(604, true)
        }
    }

    useEffect(() => {
        if (addressValidated) {
            handleStripeIntegration()
        }
    }, [addressValidated]) // eslint-disable-line react-hooks/exhaustive-deps

    const openWindowCentered = ({ url, w, h }) => {
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, "_blank",
            `
          name=linkingSquare,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
        )
        if (window.focus) newWindow.focus();
    }

    const confirmProducts = async () => {
        setIsLoading(true)
        let aux = []
        products.map(({ id, quantity, optionSetId, optionId }) => {
            if (quantity > 0) {
                let productObj = {
                    id,
                    optionSetId,
                    optionId,
                    quantity
                }
                aux.push(productObj)
            }
            return null;
        })
        try {
            await axios.post(`${BASE_URL}/business/${businesses[0].id}/order`, { products: aux }, { ...headers })
            firebaseAnalytics.logEvent('add_to_cart', {
                business_id: businesses[0].id
            })
            console.info(`BusinessId ${businesses[0].id} products confirmed`);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            handleCode(604, true)
        }
    }

    const handleSubmitButton = () => {
        if (currentStep === 'kioskPurchase' && showCheckout) {
            sendShippingInformation()
        } else if (currentStep === 'kioskPurchase' && !showCheckout) {
            setShowCheckout(true)
            confirmProducts()
        }
    }

    useEffect(() => {
        checkButtonStatus()
    })

    useEffect(() => {
        handleStripeLinkStatus()
    })

    const handleStripeLinkStatus = () => {
        if (currentStep === 'kioskPurchase' && addressValidated) {
            if (stripeUrl) {
                setIsDisabled(false)
            } else {
                setIsDisabled(true)
            }
        }
    }

    const checkButtonStatus = () => {
        setIsDisabled(
            (currentStep === 'kioskPurchase' && !itemsInCart > 0 && !itemsInCart > 0) ||
            (currentStep === 'kioskPurchase' && showCheckout && !shippingCompleted)
        )
    }

    return (
        <>
            <SectionTitle
                title={addressValidated ? "Complete order" : showCheckout ? "Shipping Info" : "Buy a kiosk"}
                text={addressValidated ? "One more step to go." : showCheckout && "Where should we ship the kiosk(s) to? Shipping cost may vary based on location."}
                secondaryText={!showCheckout && "Need help deciding? Click the chat button in the lower-right corner and we will call you right away!"}
            />
            {
                addressValidated
                    ?
                    <>
                        <div className="payOrder-container">
                            <Button mobile disabled={isDisabled || isLoading} color="white" onClick={() => openWindowCentered({ url: stripeUrl, title: 'stripe', w: 800, h: 600 })} width="auto">
                                {
                                    isLoading
                                        ? <LoaderBtn />
                                        : 'Pay for order'
                                }
                            </Button>
                        </div>
                        <div />
                    </>
                    : showCheckout
                        ? <ShippingInfo
                            setShippingCompleted={setShippingCompleted}
                            shippingData={shippingData}
                            setShippingData={setShippingData}
                            setAddressValidated={setAddressValidated}
                        />
                        : <div className="itemsKioskContainer">
                            {
                                products?.length > 0 && products.map(product => {
                                    return <ItemKiosk
                                        key={product.id}
                                        product={product}
                                        setItemsInCart={setItemsInCart}
                                        itemsInCart={itemsInCart}
                                        products={products}
                                        businesses={businesses}
                                    />
                                })
                            }
                        </div>
            }
            {
                !addressValidated && <div className="onboardingBtnContinueContainer">
                    <ButtonNonFilled mobile width="156px" onClick={onOpenModal}>Continue later</ButtonNonFilled>
                    <div className="onboardingSubmit-container">
                        <Button mobile disabled={isDisabled || isLoading} color="white" onClick={handleSubmitButton} width="auto">
                            {
                                isLoading
                                    ? <LoaderBtn />
                                    : currentStep === 'kioskPurchase' && !showCheckout ? 'Checkout' : 'Confirm address'
                            }
                        </Button>
                        {
                            (currentStep === 'kioskPurchase' && itemsInCart > 0 && !showCheckout)
                            && <div className="onboarding-checkoutQuantity"> {itemsInCart} </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default KioskPurchase
