import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import { BASE_URL } from '../../../../api/API'

import CategoryItem from './CategoryItem'
import SearchBar from './SearchBar'
import Pagination from './Pagination'

import NoCategories from './NoCategories'

const Categories = ({ setCategoriesSelected, categoriesSelected, setNoCategories, noCategories }) => {
    const { businesses, user } = useSelector(state => state.user)
    const { accessToken } = user._delegate

    let width = window.innerWidth

    const [categoriesFiltered, setCategoriesFiltered] = useState([])

    const [categories, setCategories] = useState([]);

    const [categoriesToMap, setCategoriesToMap] = useState(categories)

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [categoriesPerPage, setCategoriesPerPage] = useState(12)

    const indexOfLastCategory = currentPage * categoriesPerPage;
    const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
    const currentCategories = categoriesToMap?.slice(indexOfFirstCategory, indexOfLastCategory)

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    const checkCategories = () => {
        if (categoriesFiltered.length > 0) {
            setCategoriesToMap(categoriesFiltered)
        } else {
            setCategoriesToMap(categories)
        }
    }

    useEffect(() => {
        checkCategories()
    }, [categoriesFiltered, categories]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        checkCategoriesPerPage()
    }, [width]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkCategoriesPerPage = () => {
        if (width > 1200) {
            setCategoriesPerPage(12)
        } else if (width > 480) {
            setCategoriesPerPage(8)
        } else if (width <= 480) {
            setCategoriesPerPage(10)
        }
    }
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(categories.length / categoriesPerPage); i++) {
        pageNumbers.push(i);
    }

    const getCategories = async () => {
        try {
            let response = await axios.get(`${BASE_URL}/business/${businesses[0].id}/category`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            if (response.data.categories.length > 0) {
                setCategories(response.data.categories)
            } else {
                setNoCategories(true)
            }
        } catch (error) {
            console.log(error.response)
        }
    }

    useEffect(() => {
        getCategories()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                noCategories
                    ? <NoCategories />
                    : <>
                        <SearchBar
                            categories={categories}
                            setCategoriesFiltered={setCategoriesFiltered}
                            setCurrentPage={setCurrentPage}
                        />
                        <div className="onboardingCategories-tabletModeContainer">
                            <div className="onboardingCategories-container">
                                {
                                    currentCategories?.map(category => {
                                        return <CategoryItem
                                            key={category.id}
                                            category={category}
                                            setCategoriesSelected={setCategoriesSelected}
                                            categoriesSelected={categoriesSelected}
                                        />
                                    })
                                }
                            </div>
                            {
                                width <= 480 && <div className={currentPage === pageNumbers.length ? "nextPage-btnContainer nextPage-disabled" : "nextPage-btnContainer"}>
                                    <ion-icon name='play' onClick={() => {
                                        if (currentPage < pageNumbers.length) {
                                            paginate(currentPage + 1)
                                        }
                                    }} />
                                </div>
                            }
                        </div>
                        <Pagination
                            paginate={paginate}
                            currentPage={currentPage}
                            pageNumbers={pageNumbers}
                        />
                    </>

            }
        </>
    )
}

export default Categories
