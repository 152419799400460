import { useEffect, useState } from "react"

export const usePhoneFormatter = () => {
    const [numberFormatted, setNumberFormatted] = useState('')
    const [numberValid, setNumberValid] = useState(false)

    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            setNumberValid(true)
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    useEffect(() => {
        if (formatPhoneNumber(numberFormatted)) {
            setNumberFormatted(formatPhoneNumber(numberFormatted))
        } else {
            setNumberValid(false)
            setNumberFormatted(numberFormatted)
        }
    }, [numberFormatted])

    return {
        setNumberFormatted,
        numberFormatted,
        numberValid
    }
}