import {useHistory} from "react-router-dom";
import React, {useEffect} from "react";
import {firebaseAnalytics} from "../../firebase";
import {Logo, Text, Title} from "../../styledComponents/styledComponents";
import {setBusiness} from "../../reduxSlices/userSlice";
import {useDispatch} from "react-redux";

const SelectBusiness = ({ businesses, userId }) => {
    const dispatch = useDispatch()
    let history = useHistory()

    const initOnboarding = (business) => {
        dispatch(setBusiness([business]))
        history.push('/business/' + userId)
        console.info(`BusinessId ${business.id} has logged in`);
        console.info(`BusinessId ${business.id} is a returning user`);
    }

    useEffect(() => {
        firebaseAnalytics.logEvent('returning_user', {
            method: 'phone'
        })
        firebaseAnalytics.logEvent('login', {
            method: 'phone'
        })
    }, [])

    return (
        <div className="accessCardContainer">
            <div className="accessCard">
                <div className="mb-4vh">
                    <Logo>KIOS</Logo>
                </div>
                <div className="accessCard-dataContainer mt-60">
                    <div className="accessCard-data">
                        <Title>Welcome back!</Title>
                        <div className="mt-8 accessCard-text">
                            <Text color="#222222">Select one of your businesses to continue</Text>
                        </div>
                        <div className="mt-40 selectBusiness-list">
                            {businesses.map((business, index) =>
                                <Text mt={5} className="selectBusiness-listItem" onClick={() => initOnboarding(business)} key={index}>{business.name}</Text>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectBusiness
