import React from 'react'
import { Button, ButtonNonFilled } from '../../../../styledComponents/styledComponents'

const CalendlyButtonsContainer = ({
    onOpenModal,
    isDisabled,
    handleSubmitButton,
    currentStep,
    noCategories,
    categoriesSelected
}) => {

    return (
        <>
            <div className="onboardingBtnContinueContainer">
                <ButtonNonFilled mobile width="156px" onClick={onOpenModal}>Continue later</ButtonNonFilled>
                <div className="onboardingSubmit-container">
                    <Button mobile disabled={isDisabled} color="white" onClick={handleSubmitButton} width="auto">
                        {
                            currentStep === 'scheduleMeeting'
                                ? 'Prepare for meeting'
                                : noCategories
                                    ? 'Continue process'
                                    : 'Save & continue'
                        }
                    </Button>
                    {
                        categoriesSelected.length > 0 && <div className="onboarding-checkoutQuantity">{categoriesSelected.length}</div>
                    }
                </div>
            </div>

        </>
    )
}

export default CalendlyButtonsContainer
