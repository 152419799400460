import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { BASE_URL } from '../../../../api/API'

import { firebaseAnalytics } from '../../../../firebase'

import { InlineWidget } from 'react-calendly'

import SectionTitle from '../../SectionTitle'
import Categories from './Categories'
import { Loader } from '../../../Loader'

import { useCurrentWidth } from '../../../../hooks/useCurrentWidth'
import CalendlyButtonsContainer from './CalendlyButtonsContainer'
import { useSelector } from 'react-redux'

const calendlyConfig = require('../../../../config/calendlyConfig.json')[process.env.REACT_APP_NODE_ENV]

const Calendly = ({
    onOpenModal,
    updateStep,
    businesses,
    headers,
    currentStep,
    onboardingStatus,
    handleCode
}) => {
    const { width } = useCurrentWidth()
    const { user, displayNameTemp, displayEmailTemp } = useSelector(state => state.user)
    const { displayName, email } = user

    const [processingEvent, setProcessingEvent] = useState(false)
    const [categoriesSynced, setCategoriesSynced] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    let name = displayNameTemp ? displayNameTemp : displayName
    let emailUser = displayEmailTemp ? displayEmailTemp : email
    let calendlyUrl = `${calendlyConfig.base}${calendlyConfig.user}${calendlyConfig.session}?name=${name}&email=${emailUser}`

    //scheduleMeeting & categories
    const [calendlyOpened, setCalendlyOpened] = useState(false)
    const [calendlyCompleted, setCalendlyCompleted] = useState(false)
    const [initialPageCalendly, setInitialPageCalendly] = useState(true)

    const [categoriesSelected, setCategoriesSelected] = useState([])
    const [noCategories, setNoCategories] = useState(false)

    const confirmCategories = async () => {
        try {
            let response = await axios.post(`${BASE_URL}/business/${businesses[0].id}/category`, { categories: categoriesSelected }, { ...headers })
            if (response.status >= 200 && response.status <= 300) {
                if (!processingEvent) {
                    firebaseAnalytics.logEvent('select_categories', {
                        business_id: businesses[0].id
                    })
                    console.info(`BusinessId ${businesses[0].id} has selected categories`);
                }
                updateStep('configMenu', 'complete')
            }
        } catch (error) {
            handleCode(604, true)
        }
    }

    useEffect(() => {
        if (currentStep === 'configMenu' && noCategories) {
            setIsDisabled(false)
        }
    }, [noCategories, currentStep])

    const handleSubmitButton = () => {
        if (currentStep === 'configMenu' && noCategories) {
            updateStep('configMenu', 'complete')
            setProcessingEvent(false)
        } else if (currentStep === 'configMenu' && categoriesSelected.length > 0) {
            confirmCategories()
        } else if (currentStep === 'scheduleMeeting' && calendlyCompleted) {
            updateStep('scheduleMeeting', 'complete')
            setProcessingEvent(false)
        }
    }

    const handleUpdateStep = () => {
        setTimeout(() => {
            updateStep('scheduleMeeting', 'complete')
        }, 3000);
    }

    useEffect(() => {
        if (calendlyCompleted) {
            firebaseAnalytics.logEvent('schedule_meeting', {
                business_id: businesses[0].id
            })
            console.info(`BusinessId ${businesses[0].id} has scheduled a meeting`);
            handleUpdateStep()
        }
    }, [calendlyCompleted]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkButtonStatus = () => {
        setIsDisabled(
            (currentStep === 'configMenu' && !categoriesSelected.length > 0 && !noCategories) ||
            (currentStep === 'scheduleMeeting' && !calendlyCompleted && !noCategories)
        )
    }

    useEffect(() => {
        checkButtonStatus()
    }, [categoriesSelected, calendlyCompleted]) // eslint-disable-line react-hooks/exhaustive-deps

    const isCalendlyEvent = (e) => {
        return e.data.event && e.data.event.indexOf('calendly') === 0;
    };

    window.addEventListener(
        'message',
        function (e) {
            if (isCalendlyEvent(e)) {
                if (e.data.event === 'calendly.date_and_time_selected') {
                    setCalendlyOpened(true)
                    setInitialPageCalendly(false)
                } else if (e.data.event === 'calendly.event_scheduled') {
                    setCalendlyOpened(false)
                    setCalendlyCompleted(true)
                }
            }
        }
    );

    const checkCategorySyncStatus = () => {
        onboardingStatus.map(step => {
            if (step[0] === 'categorySync' && step[1] === 'complete') {
                return setCategoriesSynced(true)
            }
            return step
        })
    }

    useEffect(() => {
        onboardingStatus.length > 0 && checkCategorySyncStatus()
    }, [onboardingStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <SectionTitle paddingBottom={10} title={currentStep === 'configMenu' ? "Your first menu" : "Book a virtual meeting with us"} text={currentStep === 'configMenu' ? "Select the categories for your first menu. Don't worry, you can make changes later." : "Share more about your business so we know how to best help you. You'll also see your kiosk menu live."} />
            {
                currentStep === 'configMenu'
                    ? <>
                        {
                            categoriesSynced
                                ? <Categories
                                    setCategoriesSelected={setCategoriesSelected}
                                    categoriesSelected={categoriesSelected}
                                    setNoCategories={setNoCategories}
                                    setIsDisabled={setIsDisabled}
                                    noCategories={noCategories}
                                />
                                : <div className="loaderContainerWithMessage">
                                    <Loader message="Hang tight while we sync your Square categories..." />
                                </div>
                        }
                    </>
                    : <div className="onboarding-mainSectionContainer flex-col">
                        <InlineWidget
                            styles={{
                                minWidth: `${width <= 700 ? width : width <= 900 ? width - 100 : width - 200}px`,
                                height: `${initialPageCalendly && width <= 650
                                    ? '805px'
                                    : initialPageCalendly && width <= 800
                                        ? '925px'
                                        : calendlyOpened && width <= 800
                                            ? '910px'
                                            : calendlyCompleted && width <= 1100
                                                ? '620px'
                                                : width <= 800
                                                    ? '580px'
                                                    : width >= 1250
                                                        ? '700px'
                                                        : width <= 900
                                                            ? '910px'
                                                            : '925px'
                                    }`, marginTop: '20px',
                            }}
                            url={calendlyUrl}
                            pageSettings={{
                                backgroundColor: 'ffffff',
                                hideEventTypeDetails: false,
                                hideLandingPageDetails: false,
                                primaryColor: '00a2ff',
                                textColor: '4d5055',
                            }}
                        />
                    </div>
            }
            <CalendlyButtonsContainer
                onOpenModal={onOpenModal}
                isDisabled={isDisabled}
                handleSubmitButton={handleSubmitButton}
                currentStep={currentStep}
                noCategories={noCategories}
                categoriesSelected={categoriesSelected}
            />
        </>
    )
}

export default Calendly
