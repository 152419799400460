import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OTPInput from "otp-input-react";

import { setLoading } from '../../reduxSlices/userSlice';

import { useCurrentWidth } from '../../hooks/useCurrentWidth'

import { Logo, Title, Text, Input, Button } from '../../styledComponents/styledComponents'
import AlertInput from '../AlertInput';

import { LoaderBtn } from '../LoaderBtn'

const MobileNumber = ({
    setNumberFormatted,
    numberFormatted,
    numberValid,
    onSignInSubmit,
    isVerifyingCode,
    codeVerification,
    handleVerify,
    codeEnteredWrong,
    handleSetCodeVerification,
    errorMessages
}) => {
    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.user)
    const { width } = useCurrentWidth()

    const inputFocusRef = useRef(null)
    useEffect(() => {
        inputFocusRef.current && inputFocusRef.current.focus()
    }, [])

    return (
        <div className="accessCardContainer">
            <div className="accessCard">
                <Logo>KIOS</Logo>
                <div className="accessCard-dataContainer">
                    <label className="accessCard-data">
                        <Title>{isVerifyingCode ? 'Verification' : 'Enter your mobile number'}</Title>
                        <div className="accessCard-text">
                            <Text color="#222222">{isVerifyingCode ? `Enter the code sent to ${numberFormatted}.` : 'Your number will only be used to create and access your account. Text messaging rates may apply.'}</Text>
                        </div>
                        <div className="accessCard-midContainer">
                            {
                                isVerifyingCode
                                    ?
                                    <div className="verificationInputContainer">
                                        <OTPInput
                                            value={codeVerification}
                                            onChange={handleSetCodeVerification}
                                            autoFocus
                                            OTPLength={6}
                                            otpType="number"
                                            className="character"
                                        />

                                        <div className="alert-container" style={{ top: `${width <= 480 ? '40px' : '50px'}` }}>
                                            {codeEnteredWrong && <AlertInput error="invalid">{errorMessages[603]}</AlertInput>}
                                        </div>
                                    </div>
                                    : <Input
                                        pattern="\d*"
                                        type="text"
                                        placeholder="Enter here..."
                                        ref={inputFocusRef}
                                        value={numberFormatted}
                                        disabled={loading}
                                        onChange={(e) => {
                                            setNumberFormatted(e.target.value)
                                            loading && dispatch(setLoading(false))
                                        }} onKeyPress={event => {
                                            if (event.key === 'Enter' && numberValid) {
                                                onSignInSubmit()
                                            }
                                        }
                                        } />
                            }
                        </div>
                    </label>

                    <Button color="white" onClick={isVerifyingCode ? handleVerify : onSignInSubmit} disabled={loading ? true : isVerifyingCode ? codeVerification.length < 6 : numberValid ? false : true}>
                        {
                            loading
                                ? <LoaderBtn />
                                : 'Submit'
                        }
                    </Button>
                    <div className="captchaLogo" id="recaptcha-container"></div>
                </div>
            </div>
        </div>
    )
}

export default MobileNumber
