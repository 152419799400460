import React from 'react'
import { Text } from '../../../../styledComponents/styledComponents'

const NoCategories = () => {
    return (
        <div className="noCategories-container">
            <div className="messageAlert-card">
                <ion-icon color="black" name='alert-circle-outline' />
                <div>
                    <Text fontWeight={600} fontSize="28px" color="black">Oops!</Text>
                    <Text>There are no categories in your Square account. You can continue the process and do this later.</Text>
                </div>
            </div>
        </div>
    )
}

export default NoCategories
