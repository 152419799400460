import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux'
import store from './store'

import App from './App';

import './css/styles.css'
import './css/max1300.css'
import './css/max1200.css'
import './css/max1024.css'
import './css/max920.css'
import './css/max768.css'
import './css/max600.css'
import './css/max480.css'
import './css/max375.css'

import './css/wizard.css'
import 'react-responsive-modal/styles.css';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);