import React from 'react'
import SectionTitle from '../SectionTitle'

import { Button, ButtonNonFilled } from '../../../styledComponents/styledComponents'
import { useCurrentWidth } from '../../../hooks/useCurrentWidth'

const KioskPreview = ({ onOpenModal, updateStep }) => {
    const { width } = useCurrentWidth()
    return (
        <>
            <SectionTitle title="Kiosk preview" text="Your menu will look something like this. Set up a virtual call with us to see your full menu!" />
            <div className="onboarding-mainSectionContainer">
                <div className="kioskPreviewImageContainer" style={{ width: `${width <= 768 ? width : 800}px` }}>
                    <div className="kioskPreviewImage" />
                </div>
            </div>
            <div className="onboardingBtnContinueContainer">
                <ButtonNonFilled mobile width="156px" onClick={onOpenModal}>Continue later</ButtonNonFilled>
                <div className="onboardingSubmit-container">
                    <Button mobile color="white" onClick={() => updateStep('previewKiosk', 'complete')} width="140px">
                        Next
                    </Button>
                </div>
            </div>
        </>
    )
}


export default KioskPreview
