import React from 'react'
import { Text, Button, Title } from '../styledComponents/styledComponents'

const LinkingSquare = () => {
    return (
        <div className="full-screen center-center">
            <div className="linkingSquare-card">
                <div className="linkingSquare-infoContainer">
                    <div>
                        <Title>Your Square and KIOS account are linked now!</Title>
                    </div>
                    <Text>Please close this window and continue your onboarding process.</Text>
                    <Button color="white" onClick={() => window.close()}>Close</Button>
                </div>
            </div>
        </div>
    )
}

export default LinkingSquare
