import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { useSelector } from 'react-redux';

import Access from './pages/Access';
import Business from './pages/Business'
import Onboarding from './pages/Onboarding';
import CheckoutCompleted from './pages/CheckoutCompleted';

import LinkingSquare from './pages/LinkingSquare';
import ErrorLinkingSquare from './pages/ErrorLinkingSquare';
import PaymentSucceed from './components/Onboarding/Integrations/Stripe/PaymentSucceed';
import PaymentFailed from './components/Onboarding/Integrations/Stripe/PaymentFailed';

const App = () => {
  const { user } = useSelector(state => state.user)

  return (
    <>
      {
        <BrowserRouter>
          <Switch>
            {!user && <Route exact path="/" component={Access} />}
            <Route exact path="/business/:userId" component={Business} />
            {user && <Route exact path="/onboarding" component={Onboarding} />}
            {user && <Route exact path="/checkoutCompleted" component={CheckoutCompleted} />}

            {/* linking square account */}
            <Route exact path="/linkSquare" component={LinkingSquare} />
            <Route exact path="/errorLinking" component={ErrorLinkingSquare} />

            {/* Stripe payment redirection */}
            <Route exact path="/stripe-success/:businessId" component={PaymentSucceed} />
            <Route exact path="/stripe-failed" component={PaymentFailed} />

            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      }
    </>
  )
}

export default App
