import React from 'react'

const SearchBar = ({ categories, setCategoriesFiltered, setCurrentPage }) => {

    const filterCategories = (value) => {
        let valueTrimmed = value.toUpperCase().trim()
        let categoriesFiltered = categories.filter(category => category.name.toUpperCase().indexOf(valueTrimmed) === 0 && category.name.toUpperCase().startsWith(valueTrimmed))
        setCategoriesFiltered(categoriesFiltered)
        setCurrentPage(1)
    }

    return (
        <div className="categories-searchContainer">
            <input
                className="categories-searchBar"
                type="text"
                onChange={(e) => filterCategories(e.target.value)}
                placeholder="Search"
            />
            <div className="searchIcon">
                <ion-icon name='search-outline' />
            </div>
        </div>
    )
}

export default SearchBar
