import React, { useEffect, useRef } from 'react'
import { Input, TextLabel } from '../../../styledComponents/styledComponents'
import GoogleAutoComplete from './GoogleAutoComplete'

const ShippingInfo = ({ setShippingCompleted, shippingData, setShippingData, setAddressValidated }) => {

    const { state, zip, city } = shippingData

    const inputFocusRef = useRef(null)
    useEffect(() => {
        inputFocusRef.current.focus()
    }, [])

    const handleShippingData = (e) => {
        setAddressValidated(false)
        setShippingData({
            ...shippingData,
            [e.target.name]: e.target.value
        })
    }

    const checkFields = () => {
        if (Object.values(shippingData).includes('')) {
            setShippingCompleted(false)
        } else {
            setShippingCompleted(true)
        }
    }

    useEffect(() => {
        checkFields()
    }, [shippingData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="kioskPurchase-shippingContainer">
            <div className="shippingInfo-form">
                <label>
                    <div>
                        <TextLabel>Name</TextLabel>
                        <TextLabel color="red"> *</TextLabel>
                    </div>
                    <Input
                        value={shippingData.name}
                        type="text"
                        name="name"
                        placeholder="Enter here..."
                        ref={inputFocusRef}
                        onChange={(e) => handleShippingData(e)}
                    />
                </label>
                <label>
                    <div>
                        <TextLabel>Address</TextLabel>
                        <TextLabel color="red"> *</TextLabel>
                    </div>
                    <GoogleAutoComplete
                        setAddressValidated={setAddressValidated}
                        setShippingData={setShippingData}
                        shippingData={shippingData}
                    />
                </label>
                <label>
                    <div>
                        <TextLabel>City</TextLabel>
                        <TextLabel color="red"> *</TextLabel>
                    </div>
                    <Input
                        type="text"
                        name="city"
                        placeholder="Enter here..."
                        onChange={(e) => handleShippingData(e)}
                        value={city}
                    />
                </label>

                <div className="shippingBottom-container">
                    <label>
                        <div>
                            <TextLabel>State</TextLabel>
                            <TextLabel color="red"> *</TextLabel>
                        </div>
                        <Input
                            width="218px"
                            type="text"
                            name="state"
                            placeholder="Enter here..."
                            onChange={(e) => handleShippingData(e)}
                            value={state}
                        />
                    </label>
                    <label>
                        <div>
                            <TextLabel>ZIP Code</TextLabel>
                            <TextLabel color="red"> *</TextLabel>
                        </div>
                        <Input
                            width="218px"
                            type="text"
                            name="zip"
                            placeholder="Enter here..."
                            onChange={(e) => handleShippingData(e)}
                            value={zip}
                        />
                    </label>
                </div>
            </div>
        </div>
    )
}

export default ShippingInfo
