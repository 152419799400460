import React, { useState } from 'react'

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { Input } from '../../../styledComponents/styledComponents';

const GoogleAutoComplete = ({ setShippingData, shippingData, setAddressValidated }) => {
    const width = window.innerWidth;

    const [address, setAddress] = useState('')

    const handleChange = address => {
        setAddressValidated(false)
        setAddress(address)
        setShippingData({
            ...shippingData,
            address
        })
    }

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => buildShippingInfo(results[0].address_components))
            .catch(error => console.error('Error', error));
    }

    const buildShippingInfo = (data) => {
        let aux = {
            state: '',
            zip: '',
            country: '',
            city: ''
        }
        let addressSelected = `${data[0].long_name + ' ' + data[1].long_name}`
        setAddress(addressSelected)

        data.map(data => {
            switch (data.types[0]) {
                case 'administrative_area_level_1':
                    aux.state = data.short_name
                    break;
                case 'postal_code':
                    aux.zip = data.long_name
                    break;
                case 'country':
                    aux.country = data.short_name
                    break;
                case 'political':
                    aux.city = data.long_name
                    break;
                case 'locality':
                    aux.city = data.long_name
                    break;
                default:
                    break;
            }
            setShippingData({
                ...shippingData,
                address: addressSelected.length > 0 ? addressSelected : address,
                ...aux
            })
            return data
        })
    }

    return (
        <div className="GooglePlacesInput-container">
            <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                debounce={300}
                highlightFirstSuggestion={true}
                searchOptions={{
                    componentRestrictions: {
                        country: 'US'
                    }
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div>
                        <Input
                            {...getInputProps({
                                placeholder: 'Enter here...',
                            })}
                        />
                        <div className="autocomplete-dropdown-container">
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer',
                                        width: width <= 768 ? '285px' : '433px',
                                        borderLeft: '1px solid #cecece',
                                        borderBottom: '1px solid #cecece',
                                        borderRight: '1px solid #cecece',
                                        padding: '4px',
                                        fontSize: '14px',
                                        minHeight: '32px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }
                                    : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer',
                                        width: width <= 768 ? '285px' : '433px',
                                        borderLeft: '1px solid #cecece',
                                        borderBottom: '1px solid #cecece',
                                        borderRight: '1px solid #cecece',
                                        padding: '4px',
                                        fontSize: '14px',
                                        minHeight: '32px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    };
                                return (
                                    <div
                                        key={suggestion.placeId}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    )
}

export default GoogleAutoComplete
