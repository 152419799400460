import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal as FloatingModal } from 'react-responsive-modal'
import { BASE_URL } from '../../api/API'

import { logOut } from '../../reduxSlices/userSlice'
import { setCurrentStep } from '../../reduxSlices/onboardingSlice'

import { Button, ButtonNonFilled, Text, TextLabel } from '../../styledComponents/styledComponents'

import firebase from '../../firebase'
import {useHistory} from "react-router-dom";

const Modal = ({ open, onCloseModal, unlinkAccount = false, startOver = false }) => {
    const dispatch = useDispatch()
    const { user, businesses } = useSelector(state => state.user)
    let history = useHistory()

    const [isDisabled, setIsDisabled] = useState(false)

    const [phoneNumber] = useState(user?.phoneNumber)
    let lastFourNumbers = phoneNumber?.slice([phoneNumber.length - 4])

    const closeIcon = (
        <div className="icon-close mt-5" />
    )

    const handleUnlinkAccount = async () => {
        try {
            let response = await axios.delete(`${BASE_URL}/business/${businesses[0].id}/integration/square`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + user?._delegate.accessToken
                }
            })
            dispatch(setCurrentStep(response.data.nextStep))
            history.push('/onboarding')
            onCloseModal()
            setIsDisabled(false)
        } catch (error) {
            setIsDisabled(false)
            console.log(error)
        }
    }

    const handleLogOut = () => {
        firebase.auth().signOut().then(() => {
            dispatch(logOut())
            window.location.reload()
            setIsDisabled(false)
        }).catch(error => {
            setIsDisabled(false)
            console.log(error)
        })
    }

    return (
        <>
            <FloatingModal
                open={open}
                onClose={onCloseModal}
                center
                classNames={{
                    modal: 'customModal'
                }}
                closeIcon={closeIcon}
            >
                <div>
                    <div className="messageBox-headerContainer">
                        <div className="messageBox-titleContainer">
                            <TextLabel>{unlinkAccount ? 'Unlink this account?' : 'Leaving already?'}</TextLabel>
                        </div>
                    </div>
                    <div className="messageBox-textContainer mt-6">
                        <Text>{unlinkAccount ? "Warning - this is a destructive action. Existing kiosks will no longer process payments. Proceed with caution." : startOver ? "Are you sure you want to leave?" : "You're almost finished! Save your info and pick up where you left off."}</Text>
                    </div>
                    <div className="modal-btnsContainer">
                        <ButtonNonFilled
                            bold
                            fontSize="13px"
                            color="#8C8C8C"
                            width="105px"
                            hoverColor="black"
                            height="42px"
                            onClick={onCloseModal}> Nevermind </ButtonNonFilled>
                        <Button
                            bold
                            fontSize="13px"
                            bg="#1A1A1A"
                            color="white"
                            width="85px"
                            height="42px"
                            hoverBg="#474747"
                            disabled={isDisabled}
                            onClick={() => {
                                if (unlinkAccount) {
                                    setIsDisabled(true)
                                    handleUnlinkAccount()
                                } else {
                                    setIsDisabled(true)
                                    handleLogOut()
                                }
                            }}> Confirm </Button>
                    </div>
                </div>
            </FloatingModal>
        </>
    )
}

export default Modal
