import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { BASE_URL } from '../../../api/API'

import { Text } from '../../../styledComponents/styledComponents'

const WizardItem = ({ asset, title, secondCondition = undefined, line = true }) => {
    const { user, businesses } = useSelector(state => state.user)
    const { currentStep } = useSelector(state => state.onboarding)
    const [stepCompleted, setStepCompleted] = useState(false)
    const [assetUrl, setAssetUrl] = useState(`url(../assets/wizard/${asset}.png)`)
    const [lineColor, setLineColor] = useState('#8c8c8c')

    const checkAssetUrl = () => {
        if (currentStep === asset || currentStep === secondCondition) {
            setAssetUrl(`url(../assets/wizard/${asset}-current.png)`)
            setLineColor('linear-gradient(0.25turn, #52a66f, #8c8c8c)')
        } else {
            setAssetUrl(`url(../assets/wizard/${asset}.png)`)
            setLineColor('#8c8c8c')
        }
    }

    useEffect(() => {
        checkAssetUrl()
        checkStepStatus()
    }, [currentStep]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkStepStatus = async () => {
        const { accessToken } = user._delegate
        try {
            let response = await axios.get(`${BASE_URL}/business/${businesses[0].id}/onboarding/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            Object.entries(response.data.onboardingStatus).map(element => {
                if (element[0] !== 'scheduleMeeting' && element[0] === asset && element[1] === 'complete') {
                    return setStepCompleted(true)
                } else if (element[0] === secondCondition && element[1] === 'complete') {
                    return setStepCompleted(true)
                }
                return element
            })
        } catch (error) {
            //@ Error happened trying to get the user businesses
            console.log('originated in wizard', error.message)
        }
    }

    return (
        <div className="wizardItem-container">
            <div className="wizardItem-leftContainer">
                <div style={{ backgroundImage: assetUrl }} className="wizardImage">
                    {(stepCompleted && asset !== currentStep) && <div className="wizardItem-completed" style={{
                        backgroundImage: `url(../assets/wizard/completed.png)`
                    }} />}
                </div>
                <div className="wizardItem-titleContainer">
                    <Text sm>{title}</Text>
                </div>
            </div>
            {
                (asset !== 'complete' && line) && <div className="wizardItem-rightContainer">
                    <div className="wizardCurrentLine" style={{
                        background: lineColor
                    }} />
                </div>
            }
        </div>
    )
}

export default WizardItem
