import React from 'react'
import { Text, Button, Title } from '../styledComponents/styledComponents'
import { useLocation } from 'react-router-dom'

const ErrorLinkingSquare = () => {
    const location = useLocation().search;
    let queryParams = new URLSearchParams(location);

    return (
        <div className="full-screen center-center">
            <div className="linkingSquare-card">
                <div className="linkingSquare-infoContainer">
                    <div>
                        <Title>Your Square account could not be linked!</Title>
                        <Text>Error: <b>{location.includes(400) ? '(400) Square account is already integrated with another business' : (queryParams.get('errorMessage') || location)}</b></Text>
                    </div>
                    <div className="mt-18" />
                    <Text>Please close this window and try again.</Text>
                    <Button color="white" onClick={() => {
                        // window.location.href = 'http://squareup.com/logout?country_code=us&lang_code=en&return_to=%2Flogout-redirect'
                        window.close()
                    }}>Close</Button>
                </div>
            </div>
        </div>
    )
}

export default ErrorLinkingSquare
