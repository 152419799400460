import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { BASE_URL } from '../../../../api/API'

import { InfoReceiptWrapper, Button, InfoReceiptItemWrapper, StripeText, StripePrice, StripeReceiptWrapper, StripeReceiptImage } from '../../../../styledComponents/styledComponents'

import { Loader } from '../../../Loader'

import useMessageHandler from '../../../../hooks/useMessageHandler'

import { firebaseAnalytics } from '../../../../firebase'

const PaymentSucceed = (props) => {
    const { handleCode } = useMessageHandler()

    const [processingEvent, setProcessingEvent] = useState(false)

    const [loading, setLoading] = useState(true)
    const { businessId } = props.match.params

    const [order, setOrder] = useState({
        orderNumber: '',
        paymentDate: '',
        paymentMethod: '',
        receiptUrl: '',
        totalAmount: '',
    })

    useEffect(() => {
        businessId && getOrderReceipt()
    }, [businessId]) // eslint-disable-line react-hooks/exhaustive-deps

    const getOrderReceipt = async () => {
        try {
            let response = await axios.get(`${BASE_URL}/receipt/details?businessId=${businessId}`)
            setOrder(response.data)
            const { orderNumber, trackingNumber, totalAmount, lineItems } = response.data
            if (!processingEvent) {
                setProcessingEvent(true)
                firebaseAnalytics.logEvent('purchase', {
                    currency: 'USD',
                    transaction_id: orderNumber,
                    value: totalAmount,
                    shipping: trackingNumber,
                    items: lineItems
                })
                console.info(`BusinessId ${businessId} purchase completed`);
            }
            setLoading(false)
        } catch (error) {
            handleCode(604, true)
            setTimeout(() => {
                props.history.push('/')
            }, 4000);
        }
        setProcessingEvent(false)
    }

    return (
        <div className="stripeReceipt-container">
            <StripeReceiptWrapper>
                {
                    loading
                        ? <Loader message="Please wait, generating receipt" />
                        : <>
                            <div className="stripeReceipt-innerContainer">
                                <StripeReceiptImage success />
                                <StripeText color="#767676">Paid</StripeText>
                                <StripePrice>${order.totalAmount}</StripePrice>
                            </div>
                            <div className="stripeReceipt-innerContainer">
                                <InfoReceiptWrapper>
                                    <InfoReceiptItemWrapper>
                                        <StripeText color="#767676">Order number</StripeText>
                                        <StripeText>{order.orderNumber}</StripeText>
                                    </InfoReceiptItemWrapper>
                                    <InfoReceiptItemWrapper>
                                        <StripeText color="#767676">Payment date</StripeText>
                                        <StripeText>{order.paymentDate}</StripeText>
                                    </InfoReceiptItemWrapper>
                                    <InfoReceiptItemWrapper>
                                        <StripeText color="#767676">Payment method</StripeText>
                                        <StripeText>{order.paymentMethod}</StripeText>
                                    </InfoReceiptItemWrapper>
                                </InfoReceiptWrapper>
                                <Button color="white" onClick={() => window.close()}>Close</Button>
                            </div>
                        </>
                }
            </StripeReceiptWrapper>
        </div>
    )
}

export default PaymentSucceed
