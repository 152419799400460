import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useCurrentWidth } from '../hooks/useCurrentWidth'

import {Logo, Text} from '../styledComponents/styledComponents'
import { Button, ButtonNonFilled } from '../styledComponents/styledComponents'

import Modal from './Onboarding/Modal'

const Header = () => {
    const { width } = useCurrentWidth()
    const { currentStep } = useSelector(state => state.onboarding)
    const { businesses } = useSelector(state => state.user)
    const [unlinkAccount, setUnlinkAccount] = useState(false)

    //modal
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);

    const onCloseModal = () => {
        setOpen(false)
        if (unlinkAccount) {
            setTimeout(() => {
                setUnlinkAccount(false)
            }, 500);
        }
    };

    return (
        <div className="headerContainer">
            <div className="header">
                <Modal
                    open={open}
                    onCloseModal={onCloseModal}
                    unlinkAccount={unlinkAccount}
                />
                <Logo>KIOS</Logo>
                <div className="header-btnLogoutContainer">
                    <div className="header-businessName">
                        <Text>{businesses[0].name}</Text>
                    </div>
                    {/*{currentStep === 'configMenu' && <ButtonNonFilled width={width <= 375 ? "120px" : "150px"} onClick={() => {*/}
                    {/*    onOpenModal()*/}
                    {/*    setUnlinkAccount(true)*/}
                    {/*}}>Unlink account</ButtonNonFilled>}*/}
                    <Button width="98px" color="white" onClick={onOpenModal}>Logout</Button>
                </div>
            </div>
        </div>
    )
}

export default Header
