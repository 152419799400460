import { useRef, useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../api/API'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure()

const useMessageHandler = () => {
    const toastId = useRef(null)
    const [errorMessages, setErrorMessages] = useState({})

    const handleCode = async (code, alert = false, success = false) => {
        try {
            let response = await axios.get(`${BASE_URL}/message?code=${code}`)
            if (alert && !toast.isActive(toastId.current)) {
                if (success) {
                    toastId.current = toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
                } else {
                    toastId.current = toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 })
                }
            } else if (!alert) {
                setErrorMessages({
                    ...errorMessages,
                    [code]: response.data.message
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    return {
        handleCode,
        errorMessages
    }
}

export default useMessageHandler
