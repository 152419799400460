import { configureStore } from "@reduxjs/toolkit";

import userReducer from './reduxSlices/userSlice'
import onboardingReducer from './reduxSlices/onboardingSlice'

export default configureStore({
    reducer: {
        user: userReducer,
        onboarding: onboardingReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})