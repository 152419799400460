import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentStep: '',
    products: [],
    categories: [],
    onboardingStatus: []
}

export const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload
        },
        setProducts: (state, action) => {
            state.products = action.payload
        },
        setCategories: (state, action) => {
            state.categories = action.payload
        },
        setOnboardingStatus: (state, action) => {
            state.onboardingStatus = action.payload
        }
    }
})

export const { setCurrentStep, setProducts, setCategories, setOnboardingStatus } = onboardingSlice.actions;
export default onboardingSlice.reducer;