import React from 'react'
import { Link } from 'react-router-dom'

import { Text, SubTitle } from '../../styledComponents/styledComponents'

const SectionTitle = ({ title, text, secondaryText = undefined, textLinked = undefined, to = undefined, paddingBottom = '' }) => {
    return (
        <div className="sectionTitleContainer" style={{ paddingBottom }}>
            <div>
                <div className="dotIcon-onboarding" />
            </div>
            <div className="sectionTitle-titleTextContainer">
                <div className="mb-5">
                    <SubTitle>{title}</SubTitle>
                </div>
                <Text>{text} {textLinked && <Link to={to} target="_blank">{textLinked}</Link>}</Text>
                {secondaryText && <Text>{secondaryText}</Text>}
            </div>
        </div>
    )
}

export default SectionTitle
