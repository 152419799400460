import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { BASE_URL } from '../api/API';

//global state
import { setLoading } from '../reduxSlices/userSlice';
import { setCurrentStep, setProducts, setOnboardingStatus } from '../reduxSlices/onboardingSlice';
//global state

//commons
import Header from '../components/Header';
import FabIconPhone from '../components/Onboarding/FabIconPhone';
import Wizard from '../components/Onboarding/Wizard/Wizard';
import { Loader } from '../components/Loader'
import Modal from '../components/Onboarding/Modal';

//sections
import Square from '../components/Onboarding/Integrations/Square/Square'
import Calendly from '../components/Onboarding/Integrations/Calendly/Calendly';
import KioskPreview from '../components/Onboarding/kioskPreview/KioskPreview';
import KioskPurchase from '../components/Onboarding/kioskPurchase/KioskPurchase';
import KioskCheckout from '../components/Onboarding/kioskCheckout/KioskCheckout';

//hooks
import useMessageHandler from '../hooks/useMessageHandler';

//firebase
import { firestore } from '../firebase';
import {useHistory} from "react-router-dom";

const Onboarding = () => {
    const dispatch = useDispatch()
    const { businesses, user, loading } = useSelector(state => state.user)
    const { currentStep, products, onboardingStatus } = useSelector(state => state.onboarding)
    const { accessToken } = user._delegate
    let history = useHistory()

    const { handleCode } = useMessageHandler()

    //modal
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    //state for onboarding finished
    const [onboardingFinished, setOnboardingFinished] = useState(false)

    let headers = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + accessToken
        }
    }

    const getNextStep = async () => {
        dispatch(setLoading(true))
        try {
            let response = await axios.get(`${BASE_URL}/business/${businesses[0].id}/onboarding/nextStep`, { ...headers })
            let nextStep = Object.values(response.data)
            if (nextStep[0] === 'createBusiness') {
                dispatch(setCurrentStep('squareIntegration'))
            } else if (nextStep[0] === 'configMenu') {
                dispatch(setCurrentStep(nextStep[0]))
            } else {
                dispatch(setCurrentStep(nextStep[0]))
            }
        } catch (error) {
            handleCode(604, true)
        }
        dispatch(setLoading(false))
    }

    const updateStep = async (step, status) => {
        dispatch(setLoading(true))
        try {
            let response = await axios.post(`${BASE_URL}/business/${businesses[0].id}/onboarding/step`, { [step]: status }, { ...headers })
            let nextStep = Object.values(response.data)
            dispatch(setCurrentStep(nextStep[0]))
        } catch (error) {
            handleCode(604, true)
        }
        dispatch(setLoading(false))
    }

    const getProducts = async () => {
        try {
            let response = await axios.get(`${BASE_URL}/product`)
            let products = response.data.products.map(product => {
                return {
                    ...product,
                    quantity: 0
                }
            })
            dispatch(setProducts(products))
        } catch (error) {
            handleCode(604, true)
        }
    }

    useEffect(() => {
        firestore.collection('businesses').doc(`${businesses[0].id}/private/onboarding`)
            .onSnapshot((doc) => {
                dispatch(setOnboardingStatus(Object.entries(doc.data().onboardingStatus)))
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const checkCategorySyncStatus = () => {
        const showReceipt  =
            onboardingStatus.filter(step => step[0] !== 'menuCreate' && step[0] !== 'onboardingSync' && step[1] === 'pending').length === 1
                && onboardingStatus.find(step => step[0] === 'kioskCheckout' && step[1] === 'pending')
        if (showReceipt) {
            history.push('/checkoutCompleted')
        }
    }

    useEffect(() => {
        onboardingStatus.length > 0 && checkCategorySyncStatus()
    }, [onboardingStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getProducts()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getNextStep()
    }, [user, currentStep]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="onboardingContainer">
                <Modal
                    open={open}
                    onCloseModal={onCloseModal}
                />
                <Header getNextStep={getNextStep} />
                <div className="onboarding-InnerContainer">
                    <Wizard />
                    <div className="onboardingStepsContainer">
                        <div className="currentStepContainer">

                            {(currentStep === '' || loading) && <div className="loaderContainer"><Loader /></div>}

                            {(currentStep === 'squareIntegration' && !loading) && <Square
                                getNextStep={getNextStep}
                                onboardingStatus={onboardingStatus}
                                handleCode={handleCode}
                            />}

                            {(currentStep === 'previewKiosk' && !loading) && <KioskPreview
                                updateStep={updateStep}
                                onOpenModal={onOpenModal}
                            />}

                            {((currentStep === 'scheduleMeeting' || currentStep === 'configMenu') && !loading) && <Calendly
                                onOpenModal={onOpenModal}
                                updateStep={updateStep}
                                handleCode={handleCode}
                                businesses={businesses}
                                headers={headers}
                                currentStep={currentStep}
                                onboardingStatus={onboardingStatus}
                            />
                            }

                            {(currentStep === 'kioskPurchase' && !loading) && <KioskPurchase
                                onOpenModal={onOpenModal}
                                handleCode={handleCode}
                                products={products}
                                currentStep={currentStep}
                                businesses={businesses}
                                headers={headers}
                                user={user}
                            />}

                        </div>
                    </div>
                </div>
                <FabIconPhone />
            </div>
        </>
    )
}

export default Onboarding
