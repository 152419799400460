import React, { useEffect, useState } from 'react'

import axios from 'axios';
import { BASE_URL } from '../../api/API';

import { useSelector } from 'react-redux';

import { TextLabel, Text, Input, Button, ButtonNonFilled } from '../../styledComponents/styledComponents'

import AlertInput from '../AlertInput'

import { usePhoneFormatter } from '../../hooks/usePhoneFormatter';
import useMessageHandler from '../../hooks/useMessageHandler'

import { firebaseAnalytics } from '../../firebase'

import libphonenumber from 'google-libphonenumber';

import { LoaderBtn } from '../LoaderBtn'


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
toast.configure()

const FabIconPhone = () => {
    const { handleCode } = useMessageHandler()

    const [processingEvent, setProcessingEvent] = useState(false)
    let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const { setNumberFormatted, numberFormatted } = usePhoneFormatter()
    const { businesses, user, displayNameTemp, userPhoneNumber } = useSelector(state => state.user)
    const { displayName } = user
    const { accessToken } = user._delegate

    let name = displayNameTemp ? displayNameTemp : displayName

    const [phoneInputStatus, setPhoneInputStatus] = useState('')
    const [isMessageBoxOpened, setIsMessageBoxOpened] = useState(false)
    const [isSendingMessage, setIsSendingMessage] = useState(false)

    const checkStatus = () => {
        if (userPhoneNumber.length > 0) {
            setPhoneInputStatus("success")
        } else if (userPhoneNumber.length === 0) {
            setPhoneInputStatus("alert")
        }
    }

    const sendMessage = async () => {
        let message = {
            "message": `Hey!, ${name} is asking for support. Phone number: ${numberFormatted}`,
            "phoneNum": numberFormatted
        }
        if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(numberFormatted, 'US'), 'US')) {
            try {
                setIsSendingMessage(true)
                let response = await axios.post(`${BASE_URL}/business/${businesses[0].id}/integration/smsSupport`, { ...message }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer ' + accessToken
                    }
                })
                if (!processingEvent) {
                    setProcessingEvent(true)
                    firebaseAnalytics.logEvent('request_call', {
                        business_id: businesses[0].id
                    })
                    console.info(`BusinessId ${businesses[0].id} requested a call by sms service`);
                    setProcessingEvent(false)
                }
                if (response.status === 200) {
                    handleCode(610, true, 'success')
                    setIsMessageBoxOpened(false)
                } else {
                    handleCode(604, true)
                }
                setIsSendingMessage(false)
            } catch (error) {
                setIsSendingMessage(false)
                handleCode(604, true)
                console.log(error.response)
            }
        } else {
            handleCode(601, true)
        }
    }

    useEffect(() => {
        setNumberFormatted(userPhoneNumber)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                isMessageBoxOpened
                    ? <div className="messageBox-container messageBox-containerPosition">
                        <div>
                            <div className="messageBox-headerContainer">
                                <div className="messageBox-titleContainer">
                                    <div className="icon-phone-mini" />
                                    <TextLabel className='ml-12'>Receive a call?</TextLabel>
                                </div>
                                <div className="icon-close" onClick={() => setIsMessageBoxOpened(false)} />
                            </div>
                            <div className="messageBox-textContainer">
                                <Text>Help is on the way! We’ll give you a ring right away at:</Text>
                            </div>
                            <div className="messageBox-inputContainer">
                                <Input
                                    pattern="\d*"
                                    width="276px"
                                    value={numberFormatted}
                                    status={phoneInputStatus}
                                    onBlur={checkStatus}
                                    onChange={
                                        (e) => setNumberFormatted(e.target.value)
                                    }
                                />
                                <div className="messageBox-alertContainer">
                                    <AlertInput
                                        error={userPhoneNumber.length === 0 && 'empty'}
                                    />
                                </div>
                            </div>
                            <div className="messageBox-btnsContainer">
                                <ButtonNonFilled
                                    bold
                                    fontSize="13px"
                                    color="#8C8C8C"
                                    width="105px"
                                    hoverColor="black"
                                    height="42px"
                                    onClick={() => setIsMessageBoxOpened(false)}> Nevermind </ButtonNonFilled>
                                <Button
                                    disabled={isSendingMessage}
                                    bold
                                    fontSize="13px"
                                    bg="#1A1A1A"
                                    color="white"
                                    width="85px"
                                    height="42px"
                                    hoverBg="#474747"
                                    onClick={sendMessage}>
                                    {
                                        isSendingMessage
                                            ? <LoaderBtn />
                                            : 'Confirm'
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                    : <div className="fab-phoneIcon" onClick={() => setIsMessageBoxOpened(true)}>
                        <div className="icon-phone" />
                    </div>
            }
        </>
    )
}

export default FabIconPhone
